import { Component, Input } from '@angular/core';
import { RenderableHidden } from '../Models/renderable-hidden';
import { BaseFormControlComponent } from '../Components/base-form-control.component';
import { RenderableBase } from '../Models/renderable-base';

@Component({
  selector: 'app-ws-hidden',
  templateUrl: '../Templates/hidden.component.html',
})
export class HiddenComponent extends BaseFormControlComponent {
  protected override _renderable: RenderableHidden;
  @Input()
  public override get renderable(): RenderableHidden {
    return this._renderable;
  }
  public override set renderable(value: RenderableBase<any>) {
    this._renderable = value as RenderableHidden;
  }
}
