import { Component, Input } from '@angular/core';
import { BaseFormControlComponent } from '../Components/base-form-control.component';
import { RenderableBase } from '../Models/renderable-base';
import { RenderableInput } from '../Models/renderable-input';
import { Survey } from '../Models/survey';
import { SurveyService } from '../Services/survey.service';

@Component({
  selector: 'app-ws-currency',
  templateUrl: '../Templates/currency.component.html',
  styleUrls: ['../Styles/currency.component.scss'],
})
export class CurrencyComponent extends BaseFormControlComponent {
  protected override _renderable: RenderableInput;
  @Input()
  public override get renderable(): RenderableInput {
    return this._renderable;
  }
  public override set renderable(value: RenderableBase<any>) {
    this._renderable = value as RenderableInput;
  }

  private survey: Survey;

  public prefix: string;
  public suffix: string;
  public decimal: string;
  public thousands: string;

  constructor(surveyService: SurveyService,) {
    super(surveyService);
    this.surveyService.survey.subscribe((value) => {
      this.survey = value;

      const symbol = this.survey.currency['symbol'] || '$$$';
      this.suffix = this.prefix = '';

      if (this.survey.currency['placement'] == 's') {
        this.suffix = `&nbsp;${symbol}`;
      } else {
        this.prefix = `${symbol}&nbsp;`;
      }

      this.decimal = this.survey.currency['decimal'] || '.';
      this.thousands = this.survey.currency['thousands'] || ',';
    });
  }
}
