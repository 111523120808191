import { ValidatorFn, AbstractControl } from '@angular/forms';
import { ServiceLocator } from '../servicelocator';
import { ModelPropertyService } from '../Services/modelproperty.service';
import { GlobalService } from '../Services/global.service';
import { RenderableBase } from '../Models/renderable-base';

export function requiredValidator(renderable: RenderableBase<any>): ValidatorFn {
  const modelPropertyService = ServiceLocator.injector.get(ModelPropertyService);
  const globalService = ServiceLocator.injector.get(GlobalService);

  return (control: AbstractControl): { [key: string]: any } => {
    // shortcut. If nothingrequired was explicitly set then return empty
    if (globalService.nothingrequired) {
      return {};
    }

    const bind = renderable.bind;
    const value = control.value;

    // ok, let op: de control.value is al bijgewerkt naar de nieuw ingevulde waarde
    // doch de form.valueChanges is nog niet aangeroepen, en de dataservice loopt nu
    // dus nog een stapje achter
    // de beste oplossing die waarschijnlijk wel goed genoeg is is om nu de nieuwe waarde
    // te gebruiken boven op de 'stale' data van de dataservice
    // const data = dataService.getData();

    // let op: convert is belangrijk (maar mischien niet op de juiste plek hier)
    // want anders kan de eval in de stress raken (ja, dat gebeurt soms echt)
    // data[bind] = dataService.convertField(renderable.bind, value);

    // nothingrequired calculates might have changed, so do the eval now
    // this.viewControlService.evaluateCalculates();

    // if not relevant, don't check if required.. it's ok
    if (!modelPropertyService.isRelevant(renderable)) {
      return {};
    }

    // requiredness is in huidige XML formulier nooit een expressie die kijkt
    // naar andere velden in de data. Dus hier een makkelijke check:
    // is er een requiredness expressie ongelijk aan "0" of "false"

    const required = modelPropertyService.isRequired(bind);

    // no value and required -> REQUIRED
    const emptyArray = Array.isArray(value) && value.length === 0;
    const empty = value == null || value === false || value === '' || emptyArray;
    const result = empty && required ? { required: { bind } } : {};
    return result;
  };
}
