<ng-container *ngIf="globals.OFFLINE">
  <a (click)="toggleSettings = !toggleSettings" title="Offline Settings"
    class="btn button-primary ms-1">
    <fa-icon [icon]="['fas', 'wrench']"></fa-icon>
  </a>
  <ng-container *ngIf="amountStoredSurveys">
    <a (click)="uploadSurveys($event)" href class="ms-1 small float-end">
      <fa-icon [icon]="['fas', 'upload']"></fa-icon>
      Upload {{ amountStoredSurveys }} survey(s)
    </a>
  </ng-container>
  <settings *ngIf="toggleSettings" (close)="closeSettings()"></settings>
</ng-container>
