<app-ws-constraint-alert [renderable]="renderable" [form]="form"></app-ws-constraint-alert>

<div [formGroup]="form" *ngIf="form"
  [class]="!isValid() ? 'form-group input-group has-error' : 'form-group input-group'"
  class="my-3 p-3 border rounded bg-light shadow-sm">

  <button href
          role="button"
          class="btn btn-primary"
          type="button"
          [ngClass]="getStatus()"
          (click)="nextTab($event)">
    {{renderable.label|wsTrans:(renderable.msgid || renderable.id):renderable.bind|async|wsInterpolate|async}}
    <fa-icon [icon]="['fas', 'angle-double-right']"></fa-icon>
  </button>

</div>
