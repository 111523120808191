import { Component, Input } from '@angular/core';
import { RenderableInput } from '../Models/renderable-input';
import { BaseFormControlComponent } from '../Components/base-form-control.component';
import { RenderableBase } from '../Models/renderable-base';

@Component({
  selector: 'app-ws-input',
  templateUrl: '../Templates/input.component.html',
  styleUrls: ['../Styles/input.component.scss'],
})
export class InputComponent extends BaseFormControlComponent {
  protected override _renderable: RenderableInput;
  @Input()
  public override get renderable(): RenderableInput {
    return this._renderable;
  }
  public override set renderable(value: RenderableBase<any>) {
    this._renderable = value as RenderableInput;
  }

  public emailConstraintActive(): boolean {
    let result = false;
    if (!!this.form.controls[this.renderable.id].value) {
      result = this.getDataType() === 'email';
    }
    return result;
  }
}
