// import { Utils } from '../Utils/utils';
import { BehaviorSubject } from 'rxjs';

export class RenderableBase<T> {
  value: T;
  public parent: RenderableBase<any>;
  public bind: string;
  public id: string;
  public msgid: string;
  public extra_classes: string;
  public help: string;
  public hint: string;
  public alert: string;
  public label: string;
  public info: string;
  public type: string;
  public readonly: boolean;
  public hidden: boolean;
  public layout: string;
  public nodes_selectable: string;
  public step: string;
  public text: string;
  public output: string;
  public subrenderables: Array<RenderableBase<any>>;
  public next_page_msgid: string;
  public next_page_label: string;
  public next_page_extra_classes: string;
  public render_next_button: string;
  public orientation: string;
  public collapsed: string;
  public active: string;
  public checkbox_label: string

  // Global static counter
  private static globalCounter: number = 1;

  // Instance-specific counter property
  public counter: number;

  // subscribers can find out if the value has changed
  public valueChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  constructor(
    options: {
      value?: T;
      parent?: RenderableBase<any>;
      bind?: string;
      id?: string;
      msgid?: string;
      extra_classes?: string;
      help?: string;
      hint?: string;
      alert?: string;
      label?: string;
      info?: string;
      type?: string;
      readonly?: boolean;
      hidden?: boolean;
      layout?: string;
      nodes_selectable?: string;
      step?: string;
      text?: string;
      output?: string;
      subrenderables?: Array<RenderableBase<any>>;
      next_page_msgid?: string;
      next_page_label?: string;
      next_page_extra_classes?: string;
      render_next_button?: string;
      orientation?: string;
      collapsed?: boolean;
      active?: boolean;
      checkbox_label?: string;
    } = {},
  ) {
    // Set the instance-specific counter from the global counter
    this.counter = RenderableBase.globalCounter;

    // Increment the global counter for the next instance
    RenderableBase.globalCounter++;

    this.id = `renderable-${this.counter}`;

    // if (options['id'] == null) {
    //   if (options['bind']) {
    //     this.id = options['bind'];
    //   } else if (options['msgid'] || options['type']) {
    //     this.id = `noidspecified-${options['msgid']}-${options['type']}`;
    //   } else {
    //     this.id = Utils.guid();
    //   }
    //   delete options['id'];
    // }

    this.initializeOptions(options);
  }

  public convertValue(value) {
    // noop convert.. override in implementations
    return value;
  }

  public setValue(val: T) {
    this.value = val;
    this.valueChanged.next(true);
  }

  public static resetGlobalCounter() {
    RenderableBase.globalCounter = 1;
  }

  public lexicalValue() {
    // override this in inherited classes
    return this.value;
  }

  public initializeOptions(options: {}) {
    for (const key in options) {
      if (options.hasOwnProperty(key)) {
        const val = options[key] || null;
        if (val) {
          this[key] = val;
        }
      }
    }
  }
}
