import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { RenderableBase } from '../Models/renderable-base';
import { RenderableGroup } from '../Models/renderable-group';
import { RenderableInput } from '../Models/renderable-input';
import { RenderableSearchtree } from '../Models/renderable-searchtree';
import { ViewService } from '../Services/view.service';
import { Utils } from '../Utils/utils';
import { constraintValidator } from '../Validators/constraint';
import { datatypeValidator } from '../Validators/datatype';
import { minMaxValidator } from '../Validators/minmax';
import { requiredValidator } from '../Validators/required';
import { DataService } from './data.service';
import { Logger } from './logger.service';
import { ModelService } from './model.service';

@Injectable({
  providedIn: 'root', // make this service a singleton
})
export class ViewControlService implements OnDestroy {
  public formGroup: BehaviorSubject<UntypedFormGroup> = new BehaviorSubject<UntypedFormGroup>(null);
  private _formGroup: UntypedFormGroup = null;
  protected renderables: Array<RenderableBase<any>> = [];

  constructor(
    private dataService: DataService,
    private vs: ViewService,
    private logger: Logger,
    private modelService: ModelService,
  ) {
    // not sure how bad this is but it allows external javascript to interact with the
    // data service which e.g. is used in the ETS survey
    (window as any)['DATASERVICE'] = this.dataService;

    this.vs.renderables.subscribe((value) => {
      if (value) {
        this.setRenderables(value);
      }
    });
  }

  public ngOnDestroy() {
    (window as any)['DATASERVICE'] = null;
  }

  public evaluateCalculates(emitChanges: boolean = true) {
    // There are calculates in the model that depend on the outcome of other calculates
    // to fix this we do the evaluation of calculates twice.
    this.singleRunEvaluateCalculates(emitChanges);
    this.singleRunEvaluateCalculates(emitChanges); // 2nd run is on purpose
  }

  public singleRunEvaluateCalculates(emitChanges: boolean = true) {
    let changed = false;
    let data = this.dataService.getData(); // calculate only once. not sure if this is ok
    let properties = this.modelService.getProperties();
    for (let bindingId in properties) {
      if (properties.hasOwnProperty(bindingId)) {
        for (let mp of properties[bindingId]) {
          let calcExpr = mp['calculate'];
          if (calcExpr) {
            try {
              let val = Utils.maskedEval(calcExpr, { data });
              if (this.dataService.setValue(mp['bind'], val)) {
                changed = true;
              }
            } catch (e) {
              this.logger.error('Error occurred for ' + calcExpr + ' (' + e.message + ')');
            }
          }
        }
      }
    }

    this.dataService.refreshData(changed, emitChanges);
  }

  public recursiveToFormGroup(renderables: Array<RenderableBase<any>>) {
    let group: any = {};
    let modelServiceProperties = this.modelService.getProperties();

    renderables.forEach((r) => {
      if (r instanceof RenderableGroup) {
        group[r.id] = new UntypedFormGroup(this.recursiveToFormGroup(r.subrenderables));
        group[r.id]['modprop'] = modelServiceProperties[r.bind];
      } else {
        if (r instanceof RenderableSearchtree) {
          group['st-' + r.id] = new UntypedFormControl(r.value || '', []);
          group['st-' + r.id]['modprop'] = modelServiceProperties[r.bind];
        }

        let validators = [requiredValidator(r), constraintValidator(r), datatypeValidator(r)];

        if (r instanceof RenderableInput) {
          validators.push(minMaxValidator(r));
        }

        group[r.id] = new UntypedFormControl(r.value || '', validators);
        group[r.id]['modprop'] = modelServiceProperties[r.bind];
      }
    });
    return group;
  }

  public recursiveFillRenderbableByBind(renderables: Array<RenderableBase<any>>, resultMap) {
    if (renderables) {
      renderables.forEach((r) => {
        resultMap[r.bind] = r;
        if (r instanceof RenderableGroup) {
          this.recursiveFillRenderbableByBind(r.subrenderables, resultMap);
        }
      });
    }
  }

  public setRenderables(renderables: Array<RenderableBase<any>>) {
    this.renderables = renderables;
    this._formGroup = new UntypedFormGroup(this.recursiveToFormGroup(this.renderables));
    this.formGroup.next(this._formGroup);
    this.dataService.setFormModel(this._formGroup, this.renderables);
  }

  public getRenderablesByBind(renderables: Array<RenderableBase<any>>) {
    let renderablesByBind = {};
    this.recursiveFillRenderbableByBind(renderables, renderablesByBind);
    return renderablesByBind;
  }
}
