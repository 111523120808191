import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { RenderableSubmit } from '../Models/renderable-submit';
import { BaseFormControlComponent } from './base-form-control.component';
import { GlobalService } from '../Services/global.service';
import { CardGroupComponent } from './cardgroup.component';
import { RenderableBase } from '../Models/renderable-base';
import { SurveyService } from '../Services/survey.service';

@Component({
  selector: 'app-ws-nexttab',
  templateUrl: '../Templates/nexttab.component.html',
})
export class NextTabComponent extends BaseFormControlComponent {
  protected override _renderable: RenderableSubmit;
  @Input()
  public override get renderable(): RenderableSubmit {
    return this._renderable;
  }
  public override set renderable(value: RenderableBase<any>) {
    this._renderable = value as RenderableSubmit;
  }
  protected override _form: FormGroup<any>;
  @Input()
  override set form(control: AbstractControl) {
    this._form = control as FormGroup<any>;
  }
  override get form(): FormGroup<any> {
    return this._form;
  }


  @Input() public parentCardGroup: CardGroupComponent;

  constructor(public globals: GlobalService, surveyService: SurveyService) {
    super(surveyService);
  }

  public getStatus() {
    // return `state-${this.form.root.valid ? 'valid' : 'invalid'}`;
    // TODO: needs more thinking..
    return 'state-valid';
  }

  public isActive() {
    // the button is active if the complete form is valid
    // return this.form.root.valid;
    // TODO: needs more thinking...
    return true;
  }

  /* This posts the survey to the rest backend. Note that no page
   * timing metadata is stored. This is a basic submission component
   * probably only useful in basic surveys without pagegroups
   */
  public nextTab(event: any) {
    // TODO: check if this form (tab) was valid? or should it be left to the active state of the button?
    this.parentCardGroup.selectNextTab();
  }
}
