<div class="p-4 rounded shadow">
  <table class="table table-striped">
    <tbody>
      <ng-container *ngFor="let subrenderable of renderable.subrenderables.slice(0, getLimit()); let i = index">
        <tr [formGroup]="form" *ngIf="form && subrenderable | wsIsRelevant | async">
          <td *ngIf="!getSearchtree()" class="col-8 border-0">
            <span *ngIf="subrenderable.bind" class="fw-bold fst-italic me-2 text-secondary"> {{subrenderable.counter}}.</span>

            <span>
              {{
              subrenderable.label
              | wsTrans: subrenderable.msgid || subrenderable.id
              | async
              | wsInterpolate
              | async
              }}
            </span>
            <fa-icon [icon]="['fas', 'info-circle']" class="ms-2 text-info"
              *ngIf="(subrenderable.info | wsTrans: '__if_exists__' + subrenderable.id + '_info' : '__if_exists__' + subrenderable.bind + '_info' | async | wsInterpolate | async) as condition"
              ngbTooltip="{{subrenderable.info | wsTrans: '__if_exists__' + subrenderable.id + '_info' : '__if_exists__' + subrenderable.bind + '_info' | async | wsInterpolate | async}}">
            </fa-icon>

            <fa-icon *ngIf="isRequired(subrenderable.bind)" class="ws-required ms-1"
              [icon]="['fas', 'asterisk']"></fa-icon>
            <div class="text-muted">
              <small>
                {{
                subrenderable.help
                | wsTrans: subrenderable.id + '_help':subrenderable.bind + '_help'
                | async
                | wsInterpolate
                | async
                }}
              </small>
            </div>
            <div class="text-muted">
              <small>
                {{
                subrenderable.hint
                | wsTrans: subrenderable.id + '_hint':subrenderable.bind + '_hint'
                | async
                | wsInterpolate
                | async
                }}
              </small>
            </div>
            <small *ngIf="globals.DEBUG" class="badge bg-success me-1">
              <fa-icon [icon]="['fas', 'link']"></fa-icon>
              bind={{ subrenderable.bind || 'null' }} ({{ form.controls[subrenderable.id]?.value }})
            </small>

            <small *ngIf="globals.DEBUG && !(subrenderable.type == 'hidden')" class="badge bg-success me-1">
              <fa-icon [icon]="['fas', 'id-badge']"></fa-icon>
              id={{ subrenderable.id || 'null' }}
            </small>

            <small *ngIf="globals.DEBUG && !(subrenderable.type == 'hidden') && subrenderable.msgid"
              class="badge bg-success me-1">
              <fa-icon [icon]="['fas', 'language']"></fa-icon>
              msgid={{ subrenderable.msgid || 'null' }}
            </small>

            <small *ngIf="globals.DEBUG && !(subrenderable.type == 'hidden')" class="badge bg-info me-1">
              MASTER={{ subrenderable.label || 'null' }}
            </small>
          </td>

          <td *ngIf="getSearchtree()" class="col-8 border-0">
            <span>{{ getSearchtreeLabel(i) }}</span>
            <fa-icon *ngIf="isRequired(subrenderable.bind)" [icon]="['fas', 'asterisk']"
              class="ws-required ms-1"></fa-icon>
          </td>

          <td *ngIf="subrenderable.type === 'select'" class="col-4 btn-group btn-group-toggle border-0" tabindex="0">
            <ng-container *ngFor="let opt of castToRenderableSelect(subrenderable).options; let idx = index">
              <input id="{{ subrenderable.id }}-input-{{ idx }}" tabindex="-1" type="radio" class="btn-check"
                autocomplete="off" [formControlName]="subrenderable.id" [value]="opt.value" />

              <label tabindex="0" for="{{ subrenderable.id }}-input-{{ idx }}" class="btn btn-secondary"
                (click)="onRadioClicked(subrenderable.id, $event, opt.value)" [ngClass]="{
                inactive: isOptionInactive(subrenderable.id, opt.value),
                active: !isOptionInactive(subrenderable.id, opt.value)
              }">
                {{
                opt.label
                | wsTrans: renderable.id + '_' + opt.value:subrenderable.id + '_' + opt.value
                | async
                }}
              </label>
            </ng-container>
          </td>

          <td *ngIf="subrenderable.type !== 'select'" class="col-4 border-0">
            <app-df-renderable class="hide-title" [renderable]="subrenderable" [form]="form"></app-df-renderable>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>