import { ValidatorFn, AbstractControl } from '@angular/forms';
import { ServiceLocator } from '../servicelocator';
import { ModelService } from '../Services/model.service';
import { ModelPropertyService } from '../Services/modelproperty.service';
import { DataService } from '../Services/data.service';
import { Logger } from '../Services/logger.service';
import { RenderableBase } from '../Models/renderable-base';

export function datatypeValidator(renderable: RenderableBase<any>): ValidatorFn {

  const modelService = ServiceLocator.injector.get(ModelService);
  const modelPropertyService = ServiceLocator.injector.get(ModelPropertyService);
  const dataService = ServiceLocator.injector.get(DataService);


  return (control: AbstractControl): {[key: string]: any} => {

    const bind = renderable.bind;
    let meetsConstraint = true; // default no error
    const value = control.value;

    // ok, let op: de control.value is al bijgewerkt naar de nieuw ingevulde waarde
    // doch de form.valueChanges is nog niet aangeroepen, en de dataservice loopt nu
    // dus nog een stapje achter
    // de beste oplossing die waarschijnlijk wel goed genoeg is is om nu de nieuwe waarde
    // te gebruiken boven op de 'stale' data van de dataservice
    const data:any = dataService.getData();
    // let op: convert is belangrijk (maar mischien niet op de juiste plek hier)
    // want anders kan de eval in de stress raken (ja, dat gebeurd echt)
    data[bind] = dataService.convertField(renderable.bind, value);

    // depended calculates might have changed, so do the eval now
    // this.viewControlService.evaluateCalculates();

    // if not relevant, don't check the constraint.. it's ok
    if (!modelPropertyService.isRelevant(renderable)) {
      return {};
    }

    // if value is undefined / null then we don't care about this value here
    if (value == null) {
      return {};
    }

    // array of props
    const props = modelService.getProperties()[bind];

    const dataConverters: any = dataService.dataConverters;

    // check for invalid constraints
    for (const prop in props) {
      if (props[prop]['datatype'] !== '') {
        const datatype = props[prop]['datatype'];
        if (datatype !== '' && datatype in dataConverters) {
          try {
            const converted = dataConverters[datatype](value);
            // check for NaN values. We consider them not to be valid too
            if (typeof(converted) === 'number' && isNaN(converted)) {
              meetsConstraint = false;
              break;
            }
          } catch (e) {
            meetsConstraint = false;
            break;
          }
        }
      }
    }

  const result = (!meetsConstraint) ? {datatype: {bind}} : {};
    return result;
  };
}
