import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { RenderableBase } from '../Models/renderable-base';

@Component({
  selector: 'app-ws-constraint-alert',
  templateUrl: '../Templates/constraint-alert.component.html',
})
export class ConstraintAlertComponent {
  @Input() public renderable: RenderableBase<any>;
  @Input() public form: UntypedFormGroup;
}
