import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'app-hr',
  template: `
    <section *ngIf="replaceVariables(condition) | wsInterpolate | async">
      <hr
        [class]="replaceVariables(class) | wsInterpolate | async"
        />
    </section>
  `,
})
export class HrComponent {
  @Input() bind: string = '';
  @Input() type: string = '';
  @Input() class: string = '';
  @Input() hostClass: string = '';
  @Input() condition: string = '';

  @HostBinding('class') get dynamicHostClass() {
    return this.hostClass;
  }

  constructor() {}

  public replaceVariables(expression: string) {
    if (expression) {
      let replaced = expression.replace(/__bind__/g, this.bind);
      replaced = replaced.replace(/__type__/g, this.type);
      return replaced;
    }
    return expression || '';
  }
}
