<div class="card border-primary mb-3 w-100 p-3">
  <h4 class="card-header"
      (click)="clickSettings()"
    >{{ 'Settings' | wsTrans: 'wsapp_settings_title'|async }}
    <button (click)="closeSettings()"
      href role="button" class="float-end btn btn-primary">
      {{ 'Close' | wsTrans: 'wsapp_close_label'|async }}
    </button>

  </h4>
  <div class="card-body">

    <h4 class="card-title">{{ 'Select a survey from this list of presets' | wsTrans: 'wsapp_settings_preset_title'|async }}</h4>
    <p class="card-text">
      {{ 'Load a preset with filled in variables to load this survey' | wsTrans: 'wsapp_settings_preset_label'|async }}
    </p>
    <div>
      <ul class="list-group">
        <li class="list-group-item" *ngFor="let preset of availableSurveys?.presets">
          <a href (click)="loadPreset(preset)">{{ preset.label }}</a>
        </li>
      </ul>
    </div>


    <ng-container *ngIf="!toggleDetailedSettings">
      <h4 class="card-title mt-1">Select custom survey, locale and translation</h4>

      <div class="row">

        <div class="col-auto">
          <div class="form-group">
            <label for="survey_select">Select Survey</label>
            <select
              id="survey_select"
              [(ngModel)]="selectedSurvey"
              class="form-control">
              <option value="">-</option>
              <option *ngFor="let srv of availableSurveys?.surveys"
                [value]="srv.id">{{ srv.title }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-auto">
          <div class="form-group">
            <ng-container *ngIf="activeSurvey?.supported_locales">
              <label for="locale_select">Select Locale</label>
              <select
                *ngIf="activeSurvey?.supported_locales"
                id="locale_select"
                [(ngModel)]="selectedLocale"
                class="form-control">
                <option *ngFor="let loc of activeSurvey?.supported_locales"
                  [value]="loc">
                {{ loc }}
                </option>
              </select>
            </ng-container>
            <ng-container *ngIf="!(activeSurvey?.supported_translations)">
              <label for="locale_select">Enter Locale (xx_YY)</label>
              <input [(ngModel)]="selectedLocale"
                id="locale_select"
                class="form-control"
                *ngIf="!(activeSurvey?.supported_locales)"/>
            </ng-container>
          </div>
        </div>

        <div class="col-auto">
          <div class="form-group">
            <ng-container *ngIf="activeSurvey?.supported_translations">
              <label for="translation_select">Select Translation</label>
              <select
                id="translation_select"
                *ngIf="activeSurvey?.supported_translations"
                [(ngModel)]="selectedTranslation"
                class="form-control">
                <option *ngFor="let trans of activeSurvey?.supported_translations"
                  [value]="trans">
                {{ trans }}
                </option>
              </select>
            </ng-container>
            <ng-container *ngIf="!(activeSurvey?.supported_translations)">
              <label for="translation_select">Enter Translation (xx_YY)</label>
              <input [(ngModel)]="selectedTranslation"
                id="translation_select"
                class="form-control"
                *ngIf="!(activeSurvey?.supported_translations)"/>
            </ng-container>
          </div>
        </div>

        <div class="col-auto">
          <div class="form-group">
            <label for="referrer_input">Referrer</label>
            <input [(ngModel)]="selectedReferrer"
              id="referrer_input"
              class="form-control"/>
          </div>
        </div>

        <div class="col-auto">
          <div class="form-group">
            <label for="referrer2_input">Referrer2</label>
            <input [(ngModel)]="selectedReferrer2"
              id="referrer2_input"
              class="form-control"/>
          </div>
        </div>


      </div>

    <h4 class="card-title mt-1">Extra Survey Parameters</h4>
    <p class="card-text">
      Extra parameters such as prefilled relevancy, referer, etc.
    </p>
    <div *ngFor="let param of params; let i = index" class="row">
      <div class="col-auto">

        <div class="input-group">
          <span class="input-group-text" id="">Parameter name and value</span>
          <input type="text" class="form-control" [(ngModel)]="param.key">
          <input type="text" class="form-control" [(ngModel)]="param.value">
          <button (click)="params.splice(i, 1)" role="button" class="btn btn-secondary">x</button>
        </div>

      </div>
    </div>
    <button (click)="addEmptyParam()" role="button" class="btn btn-secondary">+</button>

    <div class="row mt-4">
      <div class="col-auto">
            <button (click)="changeSurveySettings(true)"
                    href role="button" class="form-control btn btn-primary">
              Change Survey (clear session data)
            </button>
      </div>
      <div class="col-auto">
            <button (click)="changeSurveySettings(false)"
                    href role="button" class="form-control btn btn-primary">
              Change Survey (keep session data)
            </button>
      </div>
    </div>
    </ng-container>

    <h4 class="card-title mt-5">{{ 'Reset Offline Cached Data' | wsTrans: 'wsapp_reset_offline_cache_title'|async }}</h4>
    <p class="card-text">
      {{ 'Reset the cached form, including the search trees. Note that you
      should only do this when you are online.' | wsTrans: 'wsapp_reset_offline_cache_help'|async }}
    </p>
    <button (click)="clearLocalStorage()"
      href role="button" class="btn btn-warning">
      {{ 'Clear Offline Cache' | wsTrans: 'wsapp_clear_offline_cache_button'|async }}
    </button>
  </div>

</div>
