import { Component } from '@angular/core';

@Component({
  selector: 'app-ws-app',
  templateUrl: '../Templates/bootstrap.component.html',
  styleUrls: ['../Styles/survey.component.scss'],
})
export class AppComponent {
  title = "WageIndicator Survey"
}
