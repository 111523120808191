import { Component, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { OnInit } from '@angular/core';
import { AbstractControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { RenderableBase } from '../Models/renderable-base';
import { DataService } from '../Services/data.service';
import { GlobalService } from '../Services/global.service';
import { PageGroupService } from '../Services/pagegroup.service';
import { SurveyComponent } from './survey.component';
import { Utils } from '../Utils/utils';

@Component({
  selector: 'page-group',
  templateUrl: '../Templates/pagegroup.component.html',
  styleUrls: ['../Styles/pagegroup.component.scss'],
})
export class PageGroupComponent implements OnInit, OnDestroy {
  @Input() public renderable: RenderableBase<any>;

  private _form: FormGroup<any>;
  @Input()
  set form(control: AbstractControl) {
    this._form = control as FormGroup<any>;
  }
  get form(): FormGroup<any> {
    return this._form;
  }


  @Input() public additionalClasses: string;

  public currentPageId: string = '';
  private _currentPageSubscription = null;

  constructor(
    public globals: GlobalService,
    private pgs: PageGroupService,
    private dataService: DataService,
    private sc: SurveyComponent,
  ) { }

  public ngOnInit() {
    this._currentPageSubscription = this.pgs.currentPage$.subscribe((pageId) => {
      this.currentPageId = pageId;
    });
  }

  public ngOnDestroy() {
    // HUUB: bij een survey reload..
    if (this._currentPageSubscription) {
      this._currentPageSubscription.unsubscribe();
    }
  }

  public hasPreviousPage() {
    return this.pgs.hasPreviousPage();
  }

  public hasNextPage() {
    return this.pgs.hasNextPage();
  }

  public getNextLabel() : string{
    const curPage = this.pgs.getCurrentPageRenderable();
    if (curPage) {
      let msgLabel = 'Next';
      // if (this.isSecondLastPage()) {
      //   msgLabel = 'Next';
      // }

      if (curPage.next_page_label) {
        msgLabel = curPage.next_page_label;
      }
      return msgLabel;
    }
    return '';
  }

  public getNextMsgId(): string {
    const curPage = this.pgs.getCurrentPageRenderable();
    if (curPage) {
      let msgId = 'next_label';
      if (this.isSecondLastPage()) {
        msgId = 'submit_label';
      }

      if (curPage.next_page_msgid) {
        msgId = curPage.next_page_msgid;
      }
      return msgId;
    }
    return '';
  }

  public getNextButtonExtraClasses(): string {
    const curPage = this.pgs.getCurrentPageRenderable();
    if (curPage) {
      return curPage.next_page_extra_classes;
    }
    return ''
  }

  public isSecondLastPage() {
    return this.pgs.isSecondLastPage();
  }

  public isLastPage() {
    return this.pgs.isLastPage();
  }

  public allowPreviousPage(): boolean {
    return this.pgs.allowPreviousPage();
  }

  public allowRestart(): boolean {
    return this.pgs.allowRestart();
  }

  public nextPage(event: Event) {
    event.preventDefault();
    if (this.form.valid) {
      this.pgs.submitSurvey();
      this.pgs.activateNextPage();
    } else {
      this.form.markAsTouched();

      for (let control in this.form.controls) {
        if (this.form.controls.hasOwnProperty(control)) {
          this._recursiveMarkAsTouched(this.form.controls[control]);
        }
      }

      let surveyComponent = Utils.querySelectorInShadowRoots(
        document,
        'app-ws-survey, survey-component',
      );
      let firstInvalid = null;
      if (surveyComponent && surveyComponent.shadowRoot) {
        surveyComponent = surveyComponent.shadowRoot;
      }
      if (surveyComponent) {
        firstInvalid = surveyComponent.querySelector('div[class~="ws-warning"]');
      }

      if (firstInvalid) {
        firstInvalid.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    }
  }

  public previousPage(event: Event) {
    event.preventDefault();
    this.pgs.activatePreviousPage();
  }

  protected _recursiveMarkAsTouched(control) {
    control.markAsTouched();
    if (control instanceof UntypedFormGroup) {
      for (let ctrl in control.controls) {
        if (control.controls.hasOwnProperty(ctrl)) {
          // eigenlijk zouden we nu alleen de controls die relevant (zichtbaar) zijn
          // moeten touchen, maar dit werkt ook wel gied genoeg voor nu..
          this._recursiveMarkAsTouched(control.controls[ctrl]);
        }
      }
    }
  }

  trackBySubrenderables(index: number, renderable: RenderableBase<any>): string {
    return renderable.id;
  }
}
