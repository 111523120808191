import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

import Stackedit from 'stackedit-js';

@Directive({
  selector: '[ngxStackedit]'
})
export class StackeditDirective {
  @Input() set openEdit(value: boolean) {
    if (value && !this.isOpen) {
      this.openEditor();
    }
  }

  @Input() markdownContent: string;
  @Output() closed = new EventEmitter();
  @Output() markdownContentChange = new EventEmitter<string>();
  @Output() markdownHTMLChange = new EventEmitter<string>();
  stackedit: any;
  isOpen: boolean;
  @Input() set renderMarkdown(md: string) {
    this.renderHTML(md);
  }

  constructor(private elementRef: ElementRef) {
    this.stackedit = new Stackedit();
  }

  renderHTML(markdown: string): void {
    if (markdown) {
      this.stackedit.openFile({
        name: '',
        content: { text: markdown }
      }, true /* silent mode */);
      this.stackedit.on('fileChange', (file) => { // In silent mode, the `fileChange` event is emitted only once.
        (this.elementRef.nativeElement as Element).innerHTML = file.content.html;
      });
    } else {
      (this.elementRef.nativeElement as Element).innerHTML = "";
    }
  }

  openEditor(): void {
    this.stackedit.openFile({
      name: '',
      content: {
        text: this.markdownContent || '',
        properties: {
          extensions: {
            preset: 'gfm',
            markdown: {
              newlines: true
            }
          }
        }
      }
    });
    this.isOpen = true;
    this.stackedit.on('fileChange', (file: any) => {
      this.markdownContentChange.emit(file.content.text);
      this.markdownHTMLChange.emit(file.content.html);
    });
    this.stackedit.on('close', () => {
      this.isOpen = false;
      this.closed.emit();
    });
  }

}
