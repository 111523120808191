import { DATE } from './meta';

export const environment = {
  ENV: 'acc_offline',
  WEBCOMPONENT: false,
  production: true,
  DEPLOY_URL: 'https://offline.wsapp.acc.wageindicator.org/',
  ENGINE_URL: 'https://wsengine.acc.wageindicator.org/rest/',
  WISAPI_URL: 'https://wisapi.acc.wageindicator.org/rest/trees/',
  OFFLINE: true,
  DATE: DATE,
};
