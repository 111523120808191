<ng-container *ngIf="renderable?.orientation === 'accordion'; then accordionTemplate else navTemplate"></ng-container>

<ng-template #accordionTemplate>

  <div ngbAccordion [closeOthers]="true" [destroyOnHide]="true">
    <div ngbAccordionItem *ngFor="
      let subrenderable of renderable.subrenderables;
      trackBy: trackBySubrenderables;
      let i = index"
      [collapsed]="(subrenderable.active | wsInterpolate | async) !== 'true'">
      <h2 ngbAccordionHeader>
        <button ngbAccordionButton (click)="accordionClicked(subrenderable)">
          {{subrenderable.label | wsTrans: subrenderable.msgid || subrenderable.id | async | wsInterpolate | async }}
        </button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <app-ws-flowgroup (nextTab)="selectNextTab()" [class]="'flowgroup d-flex flex-column'"
            [renderable]="subrenderable" [renderables]="subrenderable.subrenderables"
            [form]="form.controls[subrenderable.id]" [parentCardGroup]="this"></app-ws-flowgroup>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #navTemplate>
  <ul ngbNav #nav="ngbNav" class="nav-tabs" [destroyOnHide]="false" [(activeId)]="activeId">
    <li [ngbNavItem]="subrenderable.id" *ngFor="
      let subrenderable of renderable.subrenderables;
      trackBy: trackBySubrenderables;
      let i = index
    ">
      <a ngbNavLink (click)="tabClicked(subrenderable)">
        {{ subrenderable.label | wsTrans: subrenderable.msgid || subrenderable.id | async | wsInterpolate | async }}
      </a>
      <ng-template ngbNavContent>
        <app-ws-flowgroup (nextTab)="selectNextTab()" [class]="'flowgroup d-flex flex-column'"
          [renderable]="subrenderable" [renderables]="subrenderable.subrenderables"
          (activeStateChanged)="handleActiveStateChanged($event)"
          [activeState]="(subrenderable.active | wsInterpolate | async) === 'true'"
          [form]="form.controls[subrenderable.id]" [parentCardGroup]="this"></app-ws-flowgroup>
      </ng-template>
    </li>
  </ul>
  <div class="card-block p-3 mb-3 rounded" [ngbNavOutlet]="nav"></div>
</ng-template>
