import { RenderableBase } from './renderable-base';

export class RenderableDate extends RenderableBase<string> {
  public min: string;
  public max: string;

  constructor(options: {} = {}) {
    super(options);
    this.min = options['min'] || null;
    this.max = options['max'] || null;
  }

  public override convertValue(value) {
    let converted = value;
    if (!value) {
      converted = null;
    } else if (!(value instanceof Date)) {
      // hope for the best...
      converted = new Date(value);
    }
    if (converted instanceof Date) {
      // hmm this sucks.. date input can't handle Date types
      // note: don't use toISOString since it messes with the TZs
      // converted = converted.toISOString().substring(0,10)
      const dd = ("00" + converted.getDate()).slice(-2)
      const mm = ("00" + (converted.getMonth() + 1)).slice(-2)
      const yyyy = ("0000" + converted.getFullYear()).slice(-4)
      converted = `${yyyy}-${mm}-${dd}`;
    }
    return converted;
  }
}
