import { Component, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { RenderableBase } from '../Models/renderable-base';
import { RenderableSearchtree } from '../Models/renderable-searchtree';
import { GlobalService } from '../Services/global.service';
import { RemoveDiacriticsPipe } from '../Pipes';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'searchtree-item',
  templateUrl: '../Templates/searchtree-item.component.html',
  styleUrls: ['../Styles/searchtree-item.component.scss'],
})
export class SearchtreeItemComponent implements AfterViewInit {
  @Input() public form: UntypedFormGroup;
  @Input() public level: number;
  @Input() public renderable: RenderableSearchtree;
  @Input() public children: Array<{}>;
  @Input() public specialItems: Array<{}> = [];
  @Output() public notify: EventEmitter<any> = new EventEmitter<any>();

  public expandedChild: string;
  private removeDiacriticsPipe: RemoveDiacriticsPipe;

  private hoveredContainer = null;

  constructor(private globals: GlobalService) {
    this.removeDiacriticsPipe = new RemoveDiacriticsPipe();
  }

  public sortProperty() {
    return (item) => {
      const val = item.translations[this.getDisplayLocale()];
      return this.removeDiacriticsPipe.transform(val);
    };
  }

  public getDisplayLocale(): string {
    return this.globals.getDisplayLocale();
  }

  public selectContainerClass(child: any) {
    if (this.hoveredContainer === child) {
      return ['fas', 'dot-circle'];
    } else {
      return ['far', 'circle'];
    }
  }

  public changeSelectContainerClass(event: any, child: any) {
    this.hoveredContainer = null;
    if (event.type === 'mouseover') {
      this.hoveredContainer = child;
    }
  }

  /**
   * When a searchtreeitem is clicked, emit its id
   * @param event
   * @param child
   */
  public emitSelectedChild(event: any, child: any) {
    event.preventDefault();
    this.notify.emit(child);
  }

  public onNotify(child: any) {
    this.notify.emit(child);
  }

  public ngAfterViewInit() {
    // unbind all existing jquery bindings which is necessary when embedded in e.g. plone
    // $('a.list-group-item').off();
  }

  public toggle(event: any, tgt: string) {
    // this method is necessary because of the interation in a website that
    // already has bootstrap / jquery (plone)
    event.preventDefault();
    if (this.expandedChild === tgt) {
      this.expandedChild = null;
    } else {
      this.expandedChild = tgt;
    }
  }

  trackByItems(index: number, item): string {
    return item.id;
  }
}
