import { VocabOption } from './vocaboption';

export class Vocabulary {
  public type: string;
  public id: string;
  public options: VocabOption[] = [];

  constructor(options: {
                type?: string,
                id?: string,
                options?: VocabOption[],
              } = {}) {
    this.type = options.type;
    this.id = options.id;
    this.options = options.options;
  }
}
