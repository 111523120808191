<app-ws-constraint-alert [renderable]="renderable" [form]="form" *ngIf="form"></app-ws-constraint-alert>

<div [formGroup]="form" [class]="!isValid() ? 'form-group input-group has-error' : 'form-group input-group'">

  <input *ngIf="renderable.rows === 1"
         [formControlName]="renderable.id"
         [type]="getDataType()"
         [step]="renderable.step"
         [email]="emailConstraintActive()"
         [attr.min]="renderable.min"
         [attr.max]="renderable.max"
         [readonly]="renderable.readonly"
         class="form-control"/>

  <textarea *ngIf="renderable.rows > 1"
         appAutoGrow
         [formControlName]="renderable.id"
         [readonly]="renderable.readonly"
         [rows]="renderable.rows"
         class="form-control"></textarea>
</div>
