import { Injectable }   from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessageService {

  public messages$: Observable<Array<{'type', 'message'}>>;
  protected _messages: BehaviorSubject<Array<{'type', 'message'}>> = new BehaviorSubject([]);

  constructor() {
    this.messages$ = this._messages.asObservable();
  }

  public showMessage(type = 'info', message: string): void {
    let messages = this._messages.getValue();
    messages.push({type, message});
    this._messages.next(messages);
  }

  public dismissMessage(message: {'type', 'message'}): void {
    let messages = this._messages.getValue();
    let idx = messages.indexOf(message);
    if (idx !== -1) {
      messages.splice(idx, 1);
      this._messages.next(messages);
    }
  }
}
