import { Injectable } from '@angular/core';
import { RenderableBase } from '../Models/renderable-base';
import { BackendService } from '../Services/backend.service';
import { ViewControlService } from '../Services/view-control.service';
import { DataService } from '../Services/data.service';
import { SurveyService } from '../Services/survey.service';
import { Logger } from '../Services/logger.service';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { ModelPropertyService } from './modelproperty.service';
import { Utils } from '../Utils/utils';
@Injectable({
  providedIn: 'root',
})
export class PageGroupService {
  public currentPage$: Observable<string>;
  protected pages: string[] = [];
  protected _renderables: { [key: string]: RenderableBase<any> } = {};
  private _currentPage: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(
    private backendService: BackendService,
    private logger: Logger,
    private viewControlService: ViewControlService,
    private dataService: DataService,
    private surveyService: SurveyService,
    private modelPropertyService: ModelPropertyService,
  ) {
    this.currentPage$ = this._currentPage.asObservable();
    this.surveyService.surveyInitialized.subscribe((initialized) => {
      if (initialized) {
        this.setupPageRegistry(this.surveyService.renderables);
        // restore last filled in page in survey
        this.activateInitialPage();
      }
    });
  }

  public submitSurvey() {
    let data = this.dataService.getDataForSubmission();
    // the submission is called right before the next page is activated
    // so we check if this is the second last page now
    let finalized = this.isSecondLastPage();

    // insert timing variables to be in the data for metadata
    let currentPageId = '';
    if (this._currentPage) {
      currentPageId = this._currentPage.getValue();
    }
    let nextPageId = this.findNextRelevantPage();
    let pageMetaData = this.dataService.getPageMetaData();
    pageMetaData['__page_stateId'] = nextPageId; // auld lang syne

    let pageTimeKey = `__page_time_${nextPageId}`;
    let now = Math.floor(Date.now() / 1000);
    if (pageTimeKey in pageMetaData) {
      pageMetaData[pageTimeKey] = now;
    }

    let k = `__page_time_${currentPageId}`;
    if (!(k in pageMetaData)) {
      let start = now;
      pageMetaData[k] = start;
    }

    // store the client's TZ (negative) offset to the UTC
    pageMetaData['__page_utc_offset_minutes'] = -new Date().getTimezoneOffset();

    this.backendService.submitSurvey(data, pageMetaData, finalized).subscribe(
      (resp) => {
        this.logger.debug(resp);
      },
      (error) => {
        // what should we do with this error?
        this.logger.error(error);
      },
    );

    this.dataService.saveLocalStorage();
  }

  public setCurrentPage(pageId: string) {
    this._currentPage.next(pageId);
  }

  public getPageIndex(pageId: string) {
    if (this.pages.length > 0) {
      let curIdx = this.pages.indexOf(pageId);
      if (curIdx > -1) {
        return curIdx;
      }
    }
    return 0; // default
  }

  public getCurrentIndex() {
    if (this._currentPage) {
      let currentPageId = this._currentPage.getValue();
      return this.getPageIndex(currentPageId);
    }
    return 0; // default
  }

  public getCurrentPage() {
    if (this._currentPage) {
      return this._currentPage.getValue();
    }
    return ''
  }

  public getCurrentPageRenderable() {
    if (this._currentPage) {
      let currentPageId = this._currentPage.getValue();
      return this._renderables[currentPageId]
    }
    return ''
  }

  public hasPreviousPage() {
    let newPage = this.findNextRelevantPage(false);
    return newPage !== this._currentPage.getValue();
  }

  public hasNextPage() {
    let newPage = this.findNextRelevantPage(true);
    return newPage !== this._currentPage.getValue();
  }

  public isLastPage(idx?: number) {
    if (!idx) {
      idx = this.getCurrentIndex();
    }

    let nextPageId = this._getNextRelevantPageId(idx);
    return !nextPageId;
  }

  /* is dit de een-na-laatste pagina? M.b.v. deze functie kan
   * bepaald worden of er een submit of een next button moet worden
   * getoond.
   */
  public isSecondLastPage() {
    let result = false;
    if (this.hasNextPage()) {
      let nextPageId = this.findNextRelevantPage(true);
      let nextPageIdx = this.getPageIndex(nextPageId);
      result = this.isLastPage(nextPageIdx);
    }
    return result;
  }

  public allowRestart(): boolean {
    return Utils.convertToBoolean(this.dataService.getItem('_allow_restart'));
  }

  public allowPreviousPage(): boolean {
    return Utils.convertToBoolean(this.dataService.getItem('_allow_previous_page'));
  }

  public hideProgressBar(): boolean {
    return Utils.convertToBoolean(this.dataService.getItem('_hide_progress_bar'));
  }

  public activateInitialPage() {
    let pageMetaData = this.dataService.getPageMetaData();
    if ('__page_stateId' in pageMetaData) {
      let stateId: string = <string>(pageMetaData['__page_stateId']);
      this.logger.debug(`restore stateId to: ${stateId}`);
      this.viewControlService.evaluateCalculates();
      this.setCurrentPage(stateId);
    } else {
      this.activateFirstRelevantPage();
    }
  }

  public activateFirstRelevantPage() {
    if (this.pages.length > 0) {
      let newPageId = this.pages[0];
      this.setCurrentPage(newPageId);

      // check if initial page is relevant. if not, just get the next relevant one
      let newPageRenderable = this._renderables[newPageId];
      // let relevant = new RelevantPipe(this.modelPropertyService, this.logger)
      // .transform(newPageRenderable);
      const relevant = this.modelPropertyService.isRelevant(newPageRenderable);
      if (!relevant) {
        newPageId = this.findNextRelevantPage(true);
      }
      this.setCurrentPage(newPageId);
    }
  }

  public activatePageIndex(idx: number) {
    if (this.pages.length > 0) {
      if (idx >= 0 && idx < this.pages.length) {
        let newPageId = this.pages[idx];
        this.setCurrentPage(newPageId);
      }
    }
  }

  public activatePreviousPage() {
    let newPageId = this.findNextRelevantPage(false);
    this.setCurrentPage(newPageId);
  }

  public activateNextPage() {
    let newPageId = this.findNextRelevantPage(true);
    this.setCurrentPage(newPageId);
  }

  public findNextRelevantPage(forward = true) {
    let step = forward ? 1 : -1;

    if (this.pages.length > 0) {
      let idx = this.getCurrentIndex();
      // if (idx >= 0 && idx < this.pages.length) {
      // idx += step;
      // }

      let newPageId = this._getNextRelevantPageId(idx, step);
      if (newPageId) {
        return newPageId;
      }
    }
    return this._currentPage.getValue(); // default: stay put
  }

  public setupPageRegistry(renderables: Array<RenderableBase<any>>) {
    // assumption that page groups are not deeply nested. Only on main level!
    this.pages = [];
    this._renderables = {};
    renderables.forEach((r) => {
      if (r.type === 'pagegroup') {
        this._renderables[r.id] = r;
        this.pages.push(r.id);
      }
    });
  }

  protected _getNextRelevantPageId(startIdx: number, step = 1): string {
    let idx = startIdx + step;

    for (; idx >= 0 && idx < this.pages.length; idx += step) {
      let newPageId = this.pages[idx];
      let newPageRenderable = this._renderables[newPageId];
      const relevant = this.modelPropertyService.isRelevant(newPageRenderable);
      // let relevant = new RelevantPipe(this.modelPropertyService, this.logger)
      //     .transform(newPageRenderable);
      if (relevant) {
        return newPageId;
      }
    }
    return ''
  }

  trackBySubrenderables(index: number, renderable: RenderableBase<any>): string {
    return renderable.id;
  }
}
