// logo.component.ts
import { Component, OnInit, ElementRef } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
    selector: 'log-version',
    template: '',
})
export class LogVersionComponent implements OnInit {

    constructor(private elementRef: ElementRef) { }

    ngOnInit(): void {
        this.logVersion();
    }

    private logVersion(): void {

        const asciiArt = `                                                      
        _ _ _             _____       _ _         _           
        | | | |___ ___ ___|     |___ _| |_|___ ___| |_ ___ ___ 
        | | | | .'| . | -_|-   -|   | . | |  _| .'|  _| . |  _|
        |_____|__,|_  |___|_____|_|_|___|_|___|__,|_| |___|_|  
        |   __|_ _|___|_ _ ___ _ _                             
        |__   | | |  _| | | -_| | |                            
        |_____|___|_|  \_/|___|_  |                            
                              |___|                            
        
        Built on: ${environment.DATE}
        Environment: ${environment.ENV}
        Offline: ${environment.OFFLINE} / Webcomponent: ${environment.WEBCOMPONENT}
        `;
        
        console.log(asciiArt);
    }
}
