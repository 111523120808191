import { Component, Input } from '@angular/core';
import { RenderableRichText } from '../Models/renderable-richtext';
import { BaseFormControlComponent } from '../Components/base-form-control.component';
import { RenderableBase } from '../Models/renderable-base';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'ws-richtext',
  templateUrl: '../Templates/richtext.component.html',
})
export class RichTextComponent extends BaseFormControlComponent {
  protected override _renderable: RenderableRichText;
  // protected open: BehaviorSubject<boolean> = new BehaviorSubject(false);
  // protected open$ = this.open.asObservable();
  protected open: BehaviorSubject<boolean>;
  protected open$: Observable<boolean>;
  protected markdown: string;

  public override ngOnInit() {
    this.open = new BehaviorSubject(false);
    this.open$ = this.open.asObservable();
  }

  @Input()
  public override get renderable(): RenderableRichText {
    return this._renderable;
  }
  public override set renderable(value: RenderableBase<any>) {
    this._renderable = value as RenderableRichText;
    this.markdown = this._renderable.value;
  }

  public setMarkdown(value: string) {
    this.markdown = value;
  }

  public valueChanged(value: string) {
    this.form.controls[this.renderable.id].setValue(value);
    this.form.controls[this.renderable.id].markAsTouched();
  }
}
