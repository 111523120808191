import { RenderableGroup } from './renderable-group';

export class RenderableFlowGroup extends RenderableGroup {

  constructor(options: {} = {}) {
    super(options);
    this.orientation = options['orientation'] || 'v';
  }

}
