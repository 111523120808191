import { NgModule, Injector, ApplicationRef, DoBootstrap } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { Utils } from './Utils/utils';
import { BaseFormControlComponent } from './Components/base-form-control.component';
import { SurveyComponent } from './Components/survey.component';
import { PageGroupComponent } from './Components/pagegroup.component';
import { SearchtreeComponent } from './Components/searchtree.component';
import { SearchtreeItemComponent } from './Components/searchtree-item.component';
import { AppComponent } from './Components/app.component';
import { LoadingComponent } from './Components/loading.component';
import { SelectComponent } from './Components/select.component';
import { RangeComponent } from './Components/range.component';
import { InputComponent } from './Components/input.component';
import { SubmitComponent } from './Components/submit.component';
import { NextTabComponent } from './Components/nexttab.component';
import { CheckboxComponent } from './Components/checkbox.component';
import { DateComponent } from './Components/date.component';
import { HiddenComponent } from './Components/hidden.component';
import { MatrixComponent } from './Components/matrix.component';
import { CardGroupComponent } from './Components/cardgroup.component';
import { RichTextComponent } from './Components/richtext.component';
import { CurrencyComponent } from './Components/currency.component';
import { FlowGroupComponent } from './Components/flowgroup.component';
import { ConstraintAlertComponent } from './Components/constraint-alert.component';
import { NavbarComponent } from './Components/navbar.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { DynamicFormComponent } from './Components/dynamic-form.component';
import { DynamicFormQuestionComponent } from './Components/dynamic-form-question.component';
import { OfflineSyncComponent } from './Components/offline-sync.component';
import { OfflineRestartComponent } from './Components/offline-restart.component';
import { MessageComponent } from './Components/message.component';
import { SettingsComponent } from './Components/settings.component';
import { LogVersionComponent } from './Components/log-version.component';

import {
  RelevantPipe,
  TransPipe,
  FillPipe,
  InterpolatePipe,
  RemoveDiacriticsPipe,
  SortByPipe,
  SanitizeHtmlPipe,
} from './Pipes/index';
import { RunScriptsDirective } from './Directives/runScripts';
import { AutoGrowDirective } from './Directives/autoGrow';
import { ClickOutsideDirective } from './Directives/click-outside';
import { StackeditDirective } from './Directives/stackedit.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WindowRef } from './Utils/windowref';
import { ServiceLocator } from './servicelocator';
import { environment } from '../environments/environment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import {
  Logger,
  GlobalService,
  MessageService,
  BackendService,
  WisApiService,
  ViewService,
  ModelService,
  ModelPropertyService,
  VocabularyService,
  DataService,
  PageGroupService,
  SurveyService,
  TranslationService,
} from './Services/index';
import { ServiceWorkerModule } from '@angular/service-worker';
import { createCustomElement } from '@angular/elements';
import { LinkComponent } from './Components/link.component';
import { HrComponent } from './Components/hr.component';
import { ExternalCommunicationService } from './Services/external.service';
import { CurrencyInputDirective } from './Directives/currency-input';
import { LocalStorageService } from './Services/local-storage.service';

@NgModule({
  imports: [
    BrowserModule,
    FontAwesomeModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    ServiceWorkerModule.register(`${environment.DEPLOY_URL}ngsw-worker.js`, {
      enabled: environment.OFFLINE,
      // scope: './'  // TODO: for future
    }),

  ],
  declarations: [
    InterpolatePipe,
    BaseFormControlComponent,
    SurveyComponent,
    SearchtreeComponent,
    SearchtreeItemComponent,
    DynamicFormComponent,
    DynamicFormQuestionComponent,
    PageGroupComponent,
    CardGroupComponent,
    SelectComponent,
    RangeComponent,
    RichTextComponent,
    CurrencyComponent,
    CheckboxComponent,
    InputComponent,
    SubmitComponent,
    NextTabComponent,
    DateComponent,
    HiddenComponent,
    MatrixComponent,
    ConstraintAlertComponent,
    NavbarComponent,
    FlowGroupComponent,
    RelevantPipe,
    RemoveDiacriticsPipe,
    TransPipe,
    FillPipe,
    SanitizeHtmlPipe,
    SortByPipe,
    RunScriptsDirective,
    AutoGrowDirective,
    ClickOutsideDirective,
    StackeditDirective,
    AppComponent,
    LoadingComponent,
    OfflineSyncComponent,
    OfflineRestartComponent,
    MessageComponent,
    SettingsComponent,
    LinkComponent,
    HrComponent,
    CurrencyInputDirective,
    LogVersionComponent,
  ],
  providers: [
    InterpolatePipe,
    Utils,
    Logger,
    GlobalService,
    MessageService,
    BackendService,
    WisApiService,
    ViewService,
    DataService,
    ModelService,
    ModelPropertyService,
    VocabularyService,
    PageGroupService,
    SurveyService,
    TranslationService,
    ExternalCommunicationService,
    WindowRef,
    LocalStorageService,
  ],
  exports: [
    InterpolatePipe,
  ]
})
export class AppModule implements DoBootstrap {
  constructor(private injector: Injector) {
    ServiceLocator.injector = this.injector;

    const componentName = 'survey-component';

    // Check if the custom element is already defined, when HMR reloading
    if (!customElements.get(componentName)) {
      const surveyComponent = createCustomElement(SurveyComponent, { injector });
      customElements.define('survey-component', surveyComponent);
    }
  }

  ngDoBootstrap(appRef: ApplicationRef) {
    if (!environment.WEBCOMPONENT) {
      appRef.bootstrap(AppComponent);
    }
  }
}
