import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { SurveyComponent } from './survey.component';
import { GlobalService } from '../Services/global.service';
import { RenderableMatrixGroup } from '../Models/renderable-matrixgroup';
import { Logger } from '../Services/logger.service';
import { ChangeDetectorRef } from '@angular/core';
import { ModelPropertyService } from '../Services/modelproperty.service';
import { WisApiService } from '../Services/wisapi.service';
import { RenderableSelect } from '../Models/renderable-select';

@Component({
  selector: 'app-ws-matrix',
  templateUrl: '../Templates/matrix.component.html',
  styleUrls: ['../Styles/matrix.component.scss'],
})
export class MatrixComponent implements OnInit, OnDestroy {
  @Input() public renderable: RenderableMatrixGroup;

  private _form: FormGroup<any>;
  @Input()
  set form(control: AbstractControl) {
    this._form = control as FormGroup<any>;
  }
  get form(): FormGroup<any> {
    return this._form;
  }


  protected searchtree: {};
  private searchtreesSubscription;

  constructor(
    private sc: SurveyComponent,
    public globals: GlobalService,
    private logger: Logger,
    private cdr: ChangeDetectorRef,
    private modelPropertyService: ModelPropertyService,
    private wisApiService: WisApiService,
  ) { }

  public ngOnDestroy() {
    if (this.searchtreesSubscription) {
      this.searchtreesSubscription.unsubscribe();
    }
  }

  public castToRenderableSelect(renderable) {
    return renderable as RenderableSelect;
  }

  /**
   * Initialize a searchtree if one was defined.
   */
  public ngOnInit() {
    if (this.renderable['searchtree_id']) {
      this.wisApiService.initSearchtree(
        this.renderable['searchtree_id'],
        this.globals.locale,
        this.globals.getDisplayLocale(),
        this.renderable['optionref'] || null,
        this.renderable['initial'] || null,
        this.renderable['ignoremissingtranslations'],
        true,
      );

      let searchTreeKey = this.wisApiService.getSearchTreeKey(
        this.renderable['searchtree_id'],
        this.globals.locale,
        this.globals.getDisplayLocale(),
        this.renderable['optionref'] || null,
        this.renderable['initial'] || null,
        this.renderable['ignoremissingtranslations'],
      );

      // Subscribe to full list of searchtrees
      this.searchtreesSubscription = this.wisApiService.searchtrees.subscribe(
        (result) => {
          this.searchtree = result[searchTreeKey];
          this.cdr.markForCheck();
        },
        (error) => this.logger.error('error'),
      );
    }
  }

  /**
   * Hack to get ngbRadioGroup working for dynamic forms
   * ALSO: before getting rid of this, check if toggle off still works
   * without this hack
   * @param renderableId ID of the renderable
   * @param event The click event
   * @param value the option value
   */
  public onRadioClicked(renderableId: string, event: any, value: any = null) {
    let clickedValue = value;
    if (value === null) {
      clickedValue = event.target.children[0].value;
    }

    // uncheck value if the current value was re-selected (like a toggle)
    if (String(clickedValue) === String(this.form.controls[renderableId].value)) {
      clickedValue = null;
    }

    this.form.controls[renderableId].setValue(clickedValue);
    this.form.controls[renderableId].markAsTouched();

    return false;
  }

  public isOptionInactive(renderableId: string, value: number) {
    /* a bit of a hack: we can't change the 'active' class on the radiogroup
     * this seems to be impossible. So we just add an inactive class and add
     * some special inactive styling to the scss
     */
    return String(this.form.controls[renderableId].value) !== String(value);
  }

  /**
   * Searchtree accessor
   *
   * @returns {{}}
   */
  public getSearchtree(): {} {
    return this.searchtree;
  }

  /**
   * get label in displaylocale or in locale
   *
   * @returns {label: string}
   */
  public getSearchtreeLabel(idx: number): string {
    let translatedLabel = this.searchtree['children'][idx]['translations'];
    // return translatedLabel[this.globals.getDisplayLocale()] || translatedLabel[this.globals.locale]; ??
    return translatedLabel[this.globals.getDisplayLocale()] || translatedLabel['MASTER'];
  }

  /**
   * Per default show all children of the matrix group. If the matrix group
   * refers to a searchtree, limit the number of items to the number of children
   * in the searchtree result list.
   *
   * @returns {number}
   */
  public getLimit(): number {
    let limit = Object.keys(this.renderable['subrenderables']).length;
    if (this.getSearchtree()) {
      limit = Object.keys(this.getSearchtree()['children']).length;
    }
    return limit;
  }

  // TOO: should be in mixin or base class
  public isRequired(bindingId: string): boolean {
    return this.modelPropertyService.isRequired(bindingId);
  }
}
