<app-ws-constraint-alert [renderable]="renderable" [form]="form"></app-ws-constraint-alert>
<div
  [formGroup]="form"
  *ngIf="form"
  [class]="!isValid() ? 'form-group input-group has-error' : 'form-group input-group'"
>
  <input type="hidden" [formControlName]="renderable.id" />

  <div
    ngxStackedit
    class="w-100"
    [openEdit]="open$ | async"
    (closed)="setMarkdown(renderable.value); open.next(false)"
    [markdownContent]="renderable.value"
    [renderMarkdown]="markdown"
    (markdownContentChange)="valueChanged($event)"
  >
    <div class="mt-2 mb-2">
      <fa-icon [icon]="['fas', 'spinner']" size="2x" [spin]="true"></fa-icon>
    </div>
  </div>

  <div>
    <button (click)="open.next(true)" class="btn btn-light">Edit</button>
  </div>
</div>
