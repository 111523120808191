import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageGroupService } from '../Services/pagegroup.service';
import { BackendService } from '../Services/backend.service';
import { GlobalService } from '../Services/global.service';
import { SurveyComponent } from './survey.component';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-ws-offline-sync',
  templateUrl: '../Templates/offline-sync.component.html',
  // styleUrls: ['../Styles/survey.component.scss'],
})
export class OfflineSyncComponent implements OnInit, OnDestroy {
  public amountStoredSurveys = 0;
  public toggleSettings: boolean = false;
  private _storedSurveysSubscription = null;

  constructor(
    public globals: GlobalService,
    private pgs: PageGroupService,
    private backendService: BackendService,
    private sc: SurveyComponent,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit() {
    this._storedSurveysSubscription = this.backendService.storedSurveys$.subscribe((stored) => {
      this.amountStoredSurveys = stored.length;
      this.cdr.markForCheck();
    });
  }

  public ngOnDestroy() {
    if (this._storedSurveysSubscription) {
      this._storedSurveysSubscription.unsubscribe();
    }
  }

  public closeSettings() {
    this.toggleSettings = false;
  }

  public uploadSurveys(event: any) {
    event.preventDefault();
    this.backendService.uploadStoredSurveys();
  }
}
