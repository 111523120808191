<app-ws-constraint-alert [renderable]="renderable" [form]="form" *ngIf="form"></app-ws-constraint-alert>

<div [formGroup]="form" [class]="!isValid() ? 'form-group input-group has-error' : 'form-group input-group'">
  <ng-template appCurrencyInput [options]="{
      prefix: prefix,
      suffix: suffix,
      thousands: thousands,
      decimal: decimal
    }">
    <input type="number" onclick="this.focus();"
      [attr.min]="renderable.min" [attr.max]="renderable.max" [formControlName]="renderable.id" class="form-control" />

    <span class="display-currency form-control"></span>
  </ng-template>
</div>