import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { debounceTime } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { RenderableBase } from '../Models/renderable-base';
import { RenderableGroup } from '../Models/renderable-group';
import { RenderablePageGroup } from '../Models/renderable-pagegroup';
import { RenderableSearchtree } from '../Models/renderable-searchtree';
import { Survey } from '../Models/survey';
import { BackendService } from '../Services/backend.service';
import { DataService } from '../Services/data.service';
import { GlobalService } from '../Services/global.service';
import { Logger } from '../Services/logger.service';
import { ModelService } from '../Services/model.service';
import { SurveyService } from '../Services/survey.service';
import { ViewService } from '../Services/view.service';
import { VocabularyService } from '../Services/vocabulary.service';

import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAsterisk,
  faBars,
  faCheck,
  faCircle,
  faCog,
  faEdit,
  faIdBadge,
  faLanguage,
  faLink,
  faMinus,
  faPlus,
  faPlusCircle,
  faSearch,
  faSpinner,
  faStepBackward,
  faStepForward,
  faSync,
  faTags,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUpload,
  faWrench,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
// import { config as fontawesomeConfig } from '@fortawesome/fontawesome-svg-core';

import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';

import { ExternalCommunicationService } from '../Services/external.service';
import { PageGroupService } from '../Services/pagegroup.service';
import { Utils } from '../Utils/utils';

// Make sure you tell Font Awesome to skip auto-inserting CSS into the <head>
// this allows to use a shadowdom (css is embedded manually in the styleUrls)
// UPDATE: werkt niet in plone?? dus voor nu weer uit
// fontawesomeConfig.autoAddCss = false;
@Component({
  selector: 'app-ws-survey',
  templateUrl: '../Templates/survey.component.html',
  encapsulation: environment.WEBCOMPONENT
    ? ViewEncapsulation.ShadowDom
    : ViewEncapsulation.Emulated,
  styleUrls: [
    '../Styles/survey.component.scss',
    '../../../node_modules/@fortawesome/fontawesome-svg-core/styles.css',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // imports: [
  //   DynamicFormComponent
  // ]
})
export class SurveyComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public surveyId: string;
  @Input() public debug: boolean;
  @Input() public locale: string;
  @Input() public displayLocale: string;
  @Input() public referrer: string;
  @Input() public referrer2: string;
  @Input() public sessionId: string;
  @Input() public questionHeaders: {};
  @Input() public externalContext: {};
  @Input() public queryParams: {};
  @Input() public externalSurvey: Survey;
  @Input() public reinitializeSurvey: number;

  @Output() dataChanged = new EventEmitter<{}>();
  @Output() genericEvent = new EventEmitter<{}>();

  public errorMessage: string;
  public survey: Survey;
  public renderables: any[];
  public properties: {};
  public vocabularies: {};
  public progress: number;
  public totalPages = 0;
  public searchTreesToDo = 0;
  public keysGetter = Object.keys;
  public debugData = {};
  public isDebugCollapsed = true;
  public environment = environment;
  public clickedSettings = 0;
  public initializing = false;

  private searchTreesToDoSubscription = null;
  private surveySubscription = null;
  private initializingSubscriber = null;
  private dataChangedSubscriber = null;
  private _currentPageSubscription = null;
  private genericEventListenerSubscription = null;

  constructor(
    private logger: Logger,
    private backendService: BackendService,
    private viewService: ViewService,
    private dataService: DataService,
    private modelService: ModelService,
    private surveyService: SurveyService,
    private vocabularyService: VocabularyService,
    private pgs: PageGroupService,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    public globals: GlobalService,
    public extCommService: ExternalCommunicationService,
    private library: FaIconLibrary,
  ) {
    // Add all icons that are used to the library
    library.addIcons(
      faBars,
      faAsterisk,
      faCircle,
      faPlusCircle,
      faCog,
      faSpinner,
      faSync,
      faPlus,
      faAngleDoubleRight,
      faSearch,
      faStepBackward,
      faStepForward,
      faMinus,
      faEdit,
      faAngleDoubleLeft,
      faCheckCircle,
      faTimes,
      faCheck,
      faLink,
      faIdBadge,
      faLanguage,
      faWrench,
      faUpload,
      faTrashAlt,
      faTimesCircle,
      faTags,
      faInfoCircle,
    );

    (async () => {
      try {
        let surveyRoot = Utils.querySelectorInShadowRoots(
          document,
          'app-ws-survey, survey-component',
        );
        if (surveyRoot) {
          if (!!surveyRoot.shadowRoot) {
            surveyRoot = surveyRoot.shadowRoot;
          }
          (window as any).injectStyles(surveyRoot);
        }
      } catch (error) {
        console.error('Failed to load style module:', error);
      }
    })();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['displayLocale']) {
      this.globals.setDisplayLocale(changes['displayLocale'].currentValue);
    }
    if (changes['locale']) {
      this.globals.locale = changes['locale'].currentValue;
    }
    if (changes['debug']) {
      this.globals.DEBUG = changes['debug'].currentValue;
    }
    if (changes['externalContext']) {
      this.setExternalContext(changes['externalContext'].currentValue);
    }
    if (changes['questionHeaders']) {
      this.setQuestionHeaders(changes['questionHeaders'].currentValue);
    }
    if (changes['reinitializeSurvey']) {
      this.reinitialize();
      this.dataService.setValue('session-id', this.sessionId);
    }
    if (changes['sessionId']) {
      if (changes['sessionId'].currentValue) {
        this.dataService.setValue('session-id', changes['sessionId'].currentValue);
      }
    }
    if (changes['externalSurvey']) {
      console.info('changes.externalSurvey not yet working ok..');
      // this.globals.locale = this.locale
      // this.globals.setDisplayLocale(this.displayLocale

      // let extSurvey = this.externalSurvey;
      // if (extSurvey && !(extSurvey instanceof Object)) {
      //   extSurvey = JSON.parse(extSurvey);
      // }
      // if (extSurvey) {
      //   // this.dataService.setRawData(extSurvey.data, this.queryParams);
      //   this.globals.setGlobalTranslations(extSurvey.translations);
      //   // this.dataService.setValue('session-id', this.sessionId);
      //   // this.globals.currencyProperties = extSurvey.currency;
      // }

      // this.dataService.setValue('countrycode', extSurvey.data['countrycode'])
      // this.dataService.setValue('locale', this.locale)

      // this.initialize();
    }
  }

  ngOnDestroy() {
    this.destroy();
  }

  private destroy() {
    // omdat we de survey ook als component gebruiken moeten we netjes opruimen
    if (this.initializingSubscriber) {
      this.initializingSubscriber.unsubscribe();
    }
    if (this.dataChangedSubscriber) {
      this.dataChangedSubscriber.unsubscribe();
    }
    if (this.searchTreesToDoSubscription) {
      this.searchTreesToDoSubscription.unsubscribe();
    }
    if (this.surveySubscription) {
      this.surveySubscription.unsubscribe();
    }
    if (this._currentPageSubscription) {
      this._currentPageSubscription.unsubscribe();
    }
    if (this.genericEventListenerSubscription) {
      this.genericEventListenerSubscription.unsubscribe();
    }

    // perhaps a hack.. get rid of renderables. they tend to stick around
    this.viewService.destroy();

    window['SURVEYCOMPONENT'] = null;
  }

  private setExternalContext(data) {
    if (data && !(data instanceof Object)) {
      data = JSON.parse(data);
    }
    this.extCommService.emitExternalContext(data);
  }

  private setQuestionHeaders(data) {
    if (data && !(data instanceof Object)) {
      data = JSON.parse(data);
    }
    this.extCommService.emitQuestionHeaders(data);
  }

  public ngOnInit() {
    this.initialize();
  }

  public reinitialize() {
    this.dataService.restartSurvey();
  }

  public initialize() {
    if (window['SURVEYCOMPONENT']) {
      console.error('for now only one wsapp survey component allowed');
      return; // only one allowed (for now?)
    }

    let extSurvey = this.externalSurvey;
    if (extSurvey && !(extSurvey instanceof Object)) {
      extSurvey = JSON.parse(extSurvey);
    }
    if (extSurvey) {
      this.dataService.setRawData(extSurvey.data, this.queryParams);
      this.globals.setGlobalTranslations(extSurvey.translations);
      this.dataService.setValue('session-id', this.sessionId);
      // this.globals.currencyProperties = extSurvey.currency;
    }

    this.setQuestionHeaders(this.questionHeaders);
    this.setExternalContext(this.externalContext);
    this.genericEventListenerSubscription = this.extCommService
      .genericEventListener()
      .subscribe((msg) => {
        if (msg !== null) {
          this.genericEvent.emit(msg);
        }
      });

    this.initializingSubscriber = this.surveyService.initializing.subscribe((value) => {
      this.initializing = value;

      if (!this.initializing) {
        this.debugData = this.dataService.getData();

        if (this.dataChangedSubscriber) {
          // don't know if this can happen.. but to be sure
          this.dataChangedSubscriber.unsubscribe();
        }

        // update debug data + emit to the outside world that data has changed
        // use a small debounce timer to limit the number of changes due to
        // the possible number of sequential calculated fields
        this.dataChangedSubscriber = this.dataService.dataChanged
          .pipe(debounceTime(100))
          .subscribe((val) => {
            const submissionData = this.dataService.getDataForSubmission();
            this.debugData = this.dataService.getData();
            this.dataChanged.emit(submissionData);
          });
      }

      this._currentPageSubscription = this.pgs.currentPage$.subscribe((pageId) => {
        if (this.renderables) {
          this.renderables.forEach((item, index) => {
            if (item['id'] === pageId) {
              this.setProgress(((index + 1) / this.totalPages) * 100);
            }
          });
        }

        // TODO: Dit lijkt wat overdreven vaak aangeroepen te worden...
        let surveyRoot = Utils.querySelectorInShadowRoots(
          document,
          'app-ws-survey, survey-component',
        );
        if (surveyRoot) {
          if (!!surveyRoot.shadowRoot) {
            surveyRoot = surveyRoot.shadowRoot;
          }
          const container = surveyRoot.querySelector('div[id="ws-app-inner"]');
          container.scrollIntoView();
        }
      });
    });

    this.searchTreesToDoSubscription = this.surveyService.searchTreesToDo.subscribe((value) => {
      this.searchTreesToDo = value;
      this.cdr.markForCheck();
    });

    this.surveySubscription = this.surveyService.survey.subscribe((value) => {
      if (value) {
        this.survey = value;
        this.renderables = this.viewService.getRenderables();
        this.properties = this.modelService.getProperties();
        this.vocabularies = this.vocabularyService.getVocabularies();

        this.totalPages = 0;
        if (this.renderables && this.renderables.length > 0) {
          for (const r of this.renderables) {
            if (r instanceof RenderablePageGroup) {
              this.totalPages += 1;
            }
          }
        }

        // when the survey has changed, then also reload the global
        // translations.
        // For now we only reload the globaltranslations for non-external surveys
        // so not for CBA annotations e.g.
        const globalDisplayLocale = this.globals.getDisplayLocale();
        if (globalDisplayLocale && !this.externalSurvey) {
          this.backendService.getGlobalTranslations(globalDisplayLocale).subscribe((value) => {
            if (value) {
              this.globals.setGlobalTranslations(value);
            }
          });
        }

        this.redraw();
      }
    });

    // let extSurvey = this.externalSurvey;
    // if (extSurvey && !(extSurvey instanceof Object)) {
    //   extSurvey = JSON.parse(extSurvey);
    // }
    // if (extSurvey) {
    //   this.dataService.setRawData(extSurvey.data, this.queryParams);
    //   this.globals.setGlobalTranslations(extSurvey.translations);
    //   this.dataService.setValue('session-id', this.sessionId);
    //   // this.globals.currencyProperties = extSurvey.currency;
    // }

    this.surveyService.initSurvey(
      this.surveyId,
      this.locale,
      this.displayLocale,
      this.referrer,
      this.referrer2,
      this.sessionId,
      this.queryParams,
      extSurvey,
    );

    // allow for external JS to interact with this component (e.g. cdr markforcheck)
    window['SURVEYCOMPONENT'] = this;
  }

  public setProgress(progress: number) {
    setTimeout(() => {
      this.progress = progress;
      this.cdr.markForCheck();
    }, 0);
  }

  public redraw() {
    this.cdr.markForCheck();
  }

  public showProgressBar() {
    return !this.pgs.hideProgressBar() && this.totalPages && this.totalPages > 1;
  }

  public clickSettings() {
    this.clickedSettings = (this.clickedSettings + 1) % 5;
  }

  public closeSettings() {
    this.clickedSettings = 0;
  }

  public textDirection() {
    let direction = 'ltr';
    if (this.dataService.getData().hasOwnProperty('text_direction')) {
      direction = this.dataService.getData()['text_direction'];
    }
    return direction;
  }

  public css() {
    /* inject custom CSS from survey json */
    const html = `<style>${this.survey['css']}</style>`;
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  public formatCurrencyIntl(value) {
    // create an easy access method that can be used in the XML (e.g. in gacheck)
    const symbol = this.survey.currency['symbol'];
    const locale = this.locale;
    return Utils.formatCurrencyIntl(value, { symbol, locale });
  }

  protected _findSearchTreeRenderables(renderables: Array<RenderableBase<any>>, result: any[]) {
    for (const renderable of renderables) {
      if (renderable instanceof RenderableSearchtree) {
        result.push(renderable);
      }
      if (renderable instanceof RenderableGroup) {
        this._findSearchTreeRenderables(renderable.subrenderables, result);
      }
    }
  }
}
