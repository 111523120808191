import { ValidatorFn, AbstractControl } from '@angular/forms';
import { ServiceLocator } from '../servicelocator';
import { ModelService } from '../Services/model.service';
import { ModelPropertyService } from '../Services/modelproperty.service';
import { DataService } from '../Services/data.service';
import { RenderableInput } from '../Models/renderable-input';

export function minMaxValidator(renderable: RenderableInput): ValidatorFn {
  const modelService = ServiceLocator.injector.get(ModelService);
  const modelPropertyService = ServiceLocator.injector.get(ModelPropertyService);
  const dataService = ServiceLocator.injector.get(DataService);

  return (control: AbstractControl): { [key: string]: any } => {
    const bind = renderable.bind;
    let result = {};
    const value = control.value;

    const convertedValue = dataService.convertField(renderable.bind, value);

    // if not relevant, don't check the constraint.. it's ok
    if (!modelPropertyService.isRelevant(renderable)) {
      return {};
    }

    // if value is undefined / null then we don't care about this value here
    if (value === null || value === '') {
      return {};
    }

    // array of props
    const props = modelService.getProperties()[bind];

    // get datatype
    let datatype = null;
    for (const prop in props) {
      if (props[prop]['datatype'] !== '') {
        datatype = props[prop]['datatype'];
        break;
      }
    }

    if (datatype && (datatype === 'int' || datatype == 'number')) {

      let errorDetails: any = {};

      if (typeof convertedValue !== 'number') {
        // can this happen?
        errorDetails['minmax'] = bind;
      }

      if (renderable.min && convertedValue < parseInt(renderable.min)) {
        errorDetails['min'] = bind;
      }

      if (renderable.max && convertedValue > parseInt(renderable.max)) {
        errorDetails['max'] = bind;
      }

      result = errorDetails || null;
    }

    return result;
  };
}
