import { Utils } from '../Utils/utils';
import { RenderableBase } from './renderable-base';

export class RenderableNumericBase<T> extends RenderableBase<T> {

  public override convertValue(value) {
    // convert value to numeric if it's not null
    return (value === null || value == '') ? value : +value;
  }
}
