import { Injectable } from '@angular/core';
import { Vocabulary } from '../Models/vocabulary';
import { VocabOption } from '../Models/vocaboption';

@Injectable({
  providedIn: 'root',
})
export class VocabularyService {

  protected _rawVocabs: any;
  protected _vocabularies: any;

  public setRawVocabularies(rawVocabs: {
    rawVocabs?: {}
  } = {}) {
    this._rawVocabs = rawVocabs;
  }

  public getVocabularies() {
    if (!this._vocabularies) {
      this.createVocabularies();
    }
    return this._vocabularies;
  }

  public getVocabularyById(id: string) {
    if (!this._vocabularies) {
      this.createVocabularies();
    }

    return this._vocabularies[id];
  }

  public addVocabulary(id: string, options: VocabOption[]) {
    this._vocabularies[id] = new Vocabulary({ id, options })
  }

  /**
   * Create Vocabulary objects from rawVocabs
   */
  public createVocabularies() {
    if (this._rawVocabs) {
      let vocabularies: any = {};

      for (let component in this._rawVocabs) {
        if (this._rawVocabs[component]['type'] === 'vocab') {
          vocabularies[component] = new Vocabulary(this._rawVocabs[component]);
        }
      }
      this._vocabularies = vocabularies;
    }
  }
}
