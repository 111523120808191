<log-version *ngIf="environment.WEBCOMPONENT"></log-version>

<div
  class="surveywrapper"
  class="container-fluid"
  [class]="environment.WEBCOMPONENT ? 'webcomponent' : 'no-wc'"
  id="ws-app-inner"
>
  <div class="environmentbanner"></div>

  <div class="spinner spinner-loading-survey" *ngIf="!survey || initializing">
    <fa-icon [icon]="['fas', 'spinner']" size="2x" [spin]="true"></fa-icon>
    <span class="sr-only">Loading...</span>
  </div>

  <div *ngIf="survey" [style.direction]="textDirection()">
    <div *ngIf="css()" [innerHTML]="css()"></div>

    <ngb-progressbar
      *ngIf="showProgressBar()"
      type="info"
      [striped]="false"
      (click)="clickSettings()"
      [value]="progress"
    ></ngb-progressbar>

    <settings
      *ngIf="!environment.OFFLINE && clickedSettings === 4"
      (close)="closeSettings()"
    ></settings>

    <div class="spinner" *ngIf="globals.OFFLINE && searchTreesToDo > 0">
      <fa-icon [icon]="['fas', 'spinner']" size="2x" [spin]="true"></fa-icon>
      <span class="sr-only">Loading...</span>
    </div>

    <div *ngIf="globals.DEBUG" class="card">
      <div class="card-header" role="tab" id="allDataHeader">
        <a
          class="btn btn-primary btn-sm"
          (click)="isDebugCollapsed = !isDebugCollapsed"
          [attr.aria-expanded]="!isDebugCollapsed"
          aria-controls="allData"
        >
          Data container
        </a>
        <div class="float-end">
          <span class="badge bg-primary ms-1">id {{ survey.id }}</span>
          <span *ngIf="globals.referrer" class="badge bg-info ms-1">
            referrer {{ globals.referrer }}
          </span>
          <span *ngIf="globals.referrer2" class="badge bg-info ms-1">
            referrer2 {{ globals.referrer2 }}
          </span>
          <span class="badge bg-info ms-1">{{ survey.filename }}</span>
          <span class="badge bg-info ms-1">locale: {{ debugData['locale'] }}</span>
          <span class="badge bg-info ms-1">version: {{ survey.version }}</span>
          <span class="badge bg-info ms-1">build: {{ environment.DATE }}</span>
          <span class="badge bg-warning ms-1">{{ environment.ENV }} server</span>
          <span class="badge bg-warning ms-1">DEBUG</span>
        </div>
      </div>
      <div
        id="allData"
        [ngbCollapse]="isDebugCollapsed"
        role="tabpanel"
        aria-labelledby="allDataHeader"
      >
        <div class="card-block container" *ngIf="debugData">
          <div class="data-keyval row" *ngFor="let d of keysGetter(debugData)">
            <div class="data-key col">
              {{ d }}
            </div>
            <div class="data-val col">
              {{ debugData[d] }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-ws-offline-sync></app-ws-offline-sync>

    <dynamic-form
      *ngIf="!initializing && (!globals.OFFLINE || (globals.OFFLINE && searchTreesToDo == 0))"
      [renderables]="renderables"
    ></dynamic-form>

    <!-- <small class="float-end text-muted">build: {{ environment.DATE }}</small> -->
  </div>
</div>
