<app-ws-constraint-alert [renderable]="renderable" [form]="form"></app-ws-constraint-alert>

<div [formGroup]="form" *ngIf="form"
  [class]="!isValid() ? 'form-group input-group has-error' : 'form-group input-group'">
  <select *ngIf="renderable.layout == 'dropdown' && !renderable.multiple" [formControlName]="renderable.id"
    class="form-control">
    <option *ngFor="let opt of renderable.vocabRefOptions(); trackBy: trackByOptions" [value]="opt.value">
      {{ opt.label | wsTrans: opt.label: renderable.bind + '_' + opt.value | async }}
    </option>
    <option *ngFor="let opt of renderable.vocabFromBindOptions(); trackBy: trackByOptions" [value]="opt.value">
      {{ opt.label | wsTrans: opt.label: renderable.bind + '_' + opt.value | async }}
    </option>
    <option *ngFor="let opt of renderable.options; trackBy: trackByOptions" [value]="opt.value">
      {{ opt.label | wsTrans: opt.label: renderable.bind + '_' + opt.value | async }}
    </option>
  </select>

  <div *ngIf="renderable.layout == 'radio' && !renderable.multiple" tabindex="0" role="group"
    class="btn-group btn-group-toggle d-flex flex-wrap">
    <label class="btn btn-matrixlabel flex-fill" *ngIf="renderable.left_label">
      {{ renderable.left_label | wsTrans: renderable.left_label | async }}
    </label>

    <ng-container *ngFor="let opt of renderable.vocabRefOptions(); let idx = index; trackBy: trackByOptions">
      <input id="{{ renderable.id }}-vocab-input-{{ idx }}" tabindex="-1" type="radio" class="btn-check"
        autocomplete="off" [value]="opt.value" />
      <label tabindex="0" for="{{ renderable.id }}-vocab-input-{{ idx }}" class="btn btn-secondary flex-fill"
        (click)="onRadioClicked($event, opt.value)" [ngClass]="{
          'btn-ws': needsFill(opt.value),
          inactive: inactive(opt.value),
          active: !inactive(opt.value)
        }">
        {{ opt.label | wsTrans: opt.label: renderable.bind + '_' + opt.value | async }}
      </label>
    </ng-container>

    <ng-container *ngFor="let opt of renderable.options; let idx = index">
      <input [formControlName]="renderable.id" id="{{ renderable.id }}-input-{{ idx }}" tabindex="-1" type="radio"
        class="btn-check" autocomplete="off" [value]="opt.value" />
      <label tabindex="0" for="{{ renderable.id }}-input-{{ idx }}" class="btn btn-secondary flex-fill"
        (click)="onRadioClicked($event, opt.value)" [ngClass]="{
          'btn-ws': needsFill(opt.value),
          inactive: inactive(opt.value),
          active: !inactive(opt.value)
        }">
        {{
        opt.label | wsTrans: renderable.bind + '_' + opt.value:'option_value_' + opt.value | async
        }}
      </label>
    </ng-container>


    <label class="btn btn-matrixlabel flex-fill" *ngIf="renderable.right_label">
      {{ renderable.right_label | wsTrans: renderable.id: renderable.bind | async }}
    </label>
  </div>


  <div ngbDropdown [autoClose]="false" #multiSelectDropdown="ngbDropdown" appClickOutside (clickedOutside)="onOutsideClick($event)" class="w-100 d-inline-block position-relative" *ngIf="renderable.multiple">

    <!-- Selected Items Display -->
    <div class="selected-items mt-3 d-flex flex-wrap">
      <span *ngFor="let value of form.controls[renderable.id]?.value; let idx = index; trackBy: trackByOptions"
        class="badge bg-secondary d-flex align-items-center py-2 px-3 m-1 rounded-pill">
        {{ value.label | wsTrans: value.label: renderable.bind + '_' + value.id | async }}
        <fa-icon [icon]="['fas', 'times']" class="ms-2" style="cursor: pointer;" (click)="toggleItem(value)"></fa-icon>
      </span>
    </div>

    <!-- Dropdown Toggle Button -->
    <button class="btn btn-outline-primary" id="dropdownMenuButton-{{ renderable.id }}" ngbDropdownToggle>
      <fa-icon [icon]="['fas', 'bars']"></fa-icon>
    </button>

    <!-- Dropdown Menu Items -->
    <div ngbDropdownMenu [attr.aria-labelledby]="'dropdownMenuButton-' + renderable.id" class="w-100 dropDownMenuItems">
      <div *ngFor="let item of multiSelectOptions()" class="form-check px-2 py-1">
        <input type="checkbox" [id]="renderable.id + '-item-' + item.id" (click)="toggleItem(item)" autocomplete="off"
          [checked]="isSelected(item)">
        <label [attr.for]="renderable.id + '-item-' + item.id" (click)="toggleItem(item)">
          {{ item.label | wsTrans: item.label: renderable.bind + '_' + item.id | async }}
        </label>
      </div>
    </div>
  </div>

</div>