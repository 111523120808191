import { Injectable } from '@angular/core';
import { ModelProperty } from '../Models/modelproperty';
import { Utils } from '../Utils/utils';
import { Logger } from '../Services/logger.service';

@Injectable({
  providedIn: 'root',
})
export class ModelService {
  protected _model: {};
  protected _properties: {};

  constructor(private logger: Logger) {}

  public setRawModel(
    model: {
      model?: {};
    } = {},
  ) {
    this._model = model;
    this.createProperties();
  }

  public getModel() {
    return this._model;
  }

  public getProperties(): any {
    if (!this._properties) {
      this.createProperties();
    }
    return this._properties;
  }

  /*
   * Create ModelProperty objects from model
   * return dict of ModelProperties
   */
  public createProperties() {
    let properties = {};

    if (this._model) {
      for (let bindingId in this._model['bindings']) {
        if (this._model['bindings'].hasOwnProperty(bindingId)) {
          let mps: any = this._model['bindings'][bindingId];

          for (let mp of mps) {
            if (!properties[bindingId]) {
              properties[bindingId] = [];
            }
            properties[bindingId].push(new ModelProperty(bindingId, mp));
          }
        }
      }
    }
    this._properties = properties;
  }

  // public isRequired(bindingId: string, data: {}): boolean {
  //   // check ALL properties for this bind. If one is required
  //   // then this is considered a required field (bind)

  //   let required: boolean = false;

  //   // array of props
  //   let props = this.getProperties()[bindingId];
  //   // when one of modelprops is found to be required, consider it required.
  //   for (let prop in props) {
  //     if (props[prop]['required'] !== undefined) {
  //       let expression = props[prop]['required'];
  //       if (expression !== '0' && expression !== 'false') {
  //         if (expression === 'true') {
  //           required = true;
  //         } else {
  //           required = Utils.maskedEval(props[prop]['required'], { data });
  //         }
  //         if (required) {
  //           break; // no need to look further
  //         }
  //       }
  //     }
  //   }

  //   return required;
  // }

}
