import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { RenderableBase } from '../Models/renderable-base';
import { ViewControlService } from '../Services/view-control.service';
import { DataService } from '../Services/data.service';
import { ViewService } from '../Services/view.service';
import { PageGroupService } from '../Services/pagegroup.service';
import { ChangeDetectorRef } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { CardGroupComponent } from './cardgroup.component';


@Component({
  selector: 'dynamic-form',
  templateUrl: '../Templates/dynamic-form.component.html',
})
export class DynamicFormComponent implements OnInit, OnDestroy {
  @Input() public renderables: Array<RenderableBase<any>> = [];
  @Input() public parentCardGroup: CardGroupComponent;  // TODO: why?
  public form: UntypedFormGroup;
  protected isEvaluating = false;
  private _formGroupSubscription = null;
  private renderablesSubscription = null;
  private valueChangesSubsription = null;

  constructor(
    private vcs: ViewControlService,
    private vs: ViewService,
    private ds: DataService,
    private pgs: PageGroupService,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit() {
    this.renderablesSubscription = this.vs.renderables.subscribe((value) => {
      if (value) {
        this.renderables = value;
      }
    });

    this._formGroupSubscription = this.vcs.formGroup.subscribe((value) => {
      if (value) {
        this.form = value;

        this.valueChangesSubsription = this.form.valueChanges
          .pipe(
            distinctUntilChanged()
          )
          .subscribe((value) => {
            this.onChangevalue(value);
          });
      }
    });
  }

  public ngOnDestroy() {
    if (this._formGroupSubscription) {
      this._formGroupSubscription.unsubscribe();
    }
    if (this.renderablesSubscription) {
      this.renderablesSubscription.unsubscribe();
    }
    if (this.valueChangesSubsription) {
      this.valueChangesSubsription.unsubscribe();
    }
  }

  protected _getRecursiveControls(formGroup: UntypedFormGroup, result: {} = null) {
    if (!result) {
      result = {};
    }

    for (const controlId in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(controlId)) {
        const ctrl = formGroup.controls[controlId];
        if (ctrl instanceof UntypedFormGroup) {
          this._getRecursiveControls(ctrl, result);
        } else {
          result[controlId] = ctrl;
        }
      }
    }
    return result;
  }

  protected recalculateData(repeat: number) {
    // evaluate changes and force datatype conversion
    for (let idx = 0; idx < repeat; idx++) {
      this.vcs.evaluateCalculates();
    }
  }

  protected onChangevalue(value: any) {
    // we change the values in this function (eval expressions) which triggers
    // another change event -> endless loop will occur
    // since javascript runs in a single thread we can fix this by setting
    // a simple 'semaphore' which we can check
    if (!this.isEvaluating) {
      this.isEvaluating = true; // poor man's semaphore
      this.ds.refreshData(true, true);
      // DWC has such a lot of interdependent calculations that we need
      // this loop of recalculations so many times
      this.recalculateData(3);

      // update validity for all fields on current page
      const curPage = this.pgs.getCurrentPage();
      const formGroup = this.form;
      for (const controlId in formGroup.controls) {
        // assume all pages are on top level of first formgroup
        // also if survey has no pages (curPage == '') then just update all controls
        if (controlId === curPage || curPage === '') {
          // note: not 100% sure about whether to check bind / id here..
          const page = formGroup.controls[controlId];
          if (page instanceof UntypedFormGroup) {
            const controls = this._getRecursiveControls(page);
            for (const ctrlId in controls) {
              if (controls.hasOwnProperty(ctrlId)) {
                const ctrl = controls[ctrlId];
                ctrl.updateValueAndValidity();
              }
            }
          }
        }
      }

      this.ds.refreshData(true, true);
      this.cdr.markForCheck();
      this.isEvaluating = false;
    }
  }

  trackBySubrenderables(index: number, renderable: RenderableBase<any>): string {
    return renderable.id;
  }
}
