<div *ngFor="let child of (renderable.sort ? (children | sortBy:'asc':sortProperty()) : children); trackBy: trackByItems"
      [ngClass]="'level_' + level">

  <a class="list-group-item list-group-item-action list-group-item-container"
     data-toggle="true"
     [attr.data-target]="'#lg-' + child.id"
     [attr.data-parent]="'#lg-' + renderable.id"
     [href]="'#lg-' + child.id"
     [attr.aria-expanded]="child.id === expandedChild"
     [attr.aria-controls]="'lg-' + child.id"
     *ngIf="child.children.length > 0"
     (click)="toggle($event, child.id)"
     >

    <fa-icon *ngIf="child.id === expandedChild" [icon]="['fas', 'minus']"></fa-icon>
    <fa-icon *ngIf="child.id !== expandedChild" [icon]="['fas', 'plus']"></fa-icon>

    <span class="label">
      {{ child.translations[getDisplayLocale()] || child.translations['MASTER'] }}
    </span>

    <fa-icon
      class="node-selector"
      (click)="emitSelectedChild($event, child)"
      *ngIf="child.children.length > 0 && renderable.nodes_selectable == 'true'"
      [icon]="['far', 'check-circle']">
    </fa-icon>

  </a>

  <a class="list-group-item list-group-item-action list-group-item-leaf"
     *ngIf="child.children.length == 0 && !specialItems.includes(child)"
     href="#" (click)="emitSelectedChild($event, child)">
    {{ child.translations[getDisplayLocale()] || child.translations['MASTER'] }}
  </a>

  <div *ngIf="child.children.length > 0"
       class="list-group list-group-nested"
       [ngbCollapse]="child.id !== expandedChild">

    <searchtree-item
      (notify)="onNotify($event)"
      [level]="level + 1"
      [renderable]="renderable"
      [form]="form"
      [children]="child.children">
    </searchtree-item>
  </div>
</div>
