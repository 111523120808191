export class ModelProperty {
  public bind: string;
  public relevant: string;
  public calculate: string;
  public constraint: string;
  public datatype: string;
  public required: string;
  public isdefault: string;
  public readonly: boolean;

  constructor(bind: string,
              options: {
                relevant?: string,
                calculate?: string,
                constraint?: string,
                datatype?: string,
                required?: string,
                default?: string,
                readonly?: boolean
              } = {}) {
    this.bind = bind;
    this.relevant = options.relevant;
    this.calculate = options.calculate;
    this.constraint = options.constraint;
    this.datatype = options.datatype;
    this.required = options.required;
    this.isdefault = options.default;  // default is reserved word in JS
    this.readonly = options.readonly;
  }
}
