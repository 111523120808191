<app-ws-constraint-alert [renderable]="renderable" [form]="form"></app-ws-constraint-alert>

<div [formGroup]="form" *ngIf="form" [class]="!isValid() ? 'form-group input-group has-error' : 'form-group input-group'">

  <select *ngIf="renderable.layout == 'dropdown'"
          [formControlName]="renderable.id"
          class="form-control">
    <option *ngFor="let opt of getOptions(); trackBy: trackByFunction"
            [value]="opt.value">{{opt.label|wsTrans:opt.label|async}}
    </option>
  </select>

  <div *ngIf="renderable.layout == 'radio'" class="btn-group btn-group-toggle d-flex flex-wrap">
  
    <label class="btn btn-matrixlabel flex-fill" *ngIf="renderable.left_label">
      {{ renderable.left_label|wsTrans:renderable.left_label|async }}
    </label>
    
    <ng-container *ngFor="let opt of getOptions(); trackBy: trackByFunction">
      <input type="radio" [id]="renderable.id + '_' + opt.value"
             [value]="opt.value"
             [formControlName]="renderable.id"
             class="btn-check"
             autocomplete="off">
      <label [for]="renderable.id + '_' + opt.value"
             [ngClass]="{'btn-ws': needsFill(opt.value), 'inactive': inactive(opt.value)}"
             class="btn btn-secondary flex-fill">
        {{opt.label|wsTrans:opt.label:renderable.id + '_' + opt.value|async}}
      </label>
    </ng-container>
  
    <label class="btn btn-matrixlabel flex-fill" *ngIf="renderable.right_label">
      {{ renderable.right_label|wsTrans:renderable.right_label|async }}
    </label>
  </div>
    
</div>
