<div [hidden]="sessionId || (surveyId && locale)">
  <div class="p-5 mb-4 bg-light rounded-3">
    <div class="container-fluid py-5">
      <h1 class="display-3">WageIndicator Survey ...</h1>
      <p>No survey was specified so please select one first</p>
      <settings (changed)="onSettingsChanged()"></settings>
    </div>
  </div>
</div>

<div *ngIf="ready && (sessionId || (surveyId && locale))" class="container my-3">

  <app-ws-survey
    [surveyId]="surveyId"
    [locale]="locale"
    [displayLocale]="displayLocale"
    [referrer]="referrer"
    [referrer2]="referrer2"
    [sessionId]="sessionId"
    [queryParams]="queryParams"
  ></app-ws-survey>
</div>
