import { RenderableNumericBase } from './renderable-numeric-base';
import { RenderableBase } from './renderable-base';
import { DataService } from '../Services/data.service';
import { VocabularyService } from '../Services/vocabulary.service';
import { ServiceLocator } from '../servicelocator';
import { VocabOption } from './vocaboption';

export class RenderableSelect extends RenderableBase<any> {
  public vocab: string; // what vocabulary should be used for the options
  public vocabref: string; // what vocabulary (reference) should be used for the options
  public vocabFromBind: string // load the options from the data
  public size: number; // default amount of dropdown options shown

  public multiple: boolean;
  public options: VocabOption[] = [];
  public dataService: DataService;
  public vocabularyService: VocabularyService;
  public left_label: string;
  public right_label: string;

  constructor(options: {} = {}) {
    super(options);

    this.layout = options['layout'] || 'radio';
    this.multiple = options['multiple'] || false;
    this.size = options['size'] || 3;

    // fetch service from locator
    this.vocabularyService = ServiceLocator.injector.get(VocabularyService);
    this.dataService = ServiceLocator.injector.get(DataService);

    // TODO: maybe combine standard and vocabulary options

    if (options['vocab']) {
      if (this.vocabularyService.getVocabularyById(options['vocab'])) {
        const vocabOpts = this.vocabularyService.getVocabularyById(options['vocab']).options;
        for (const opt of vocabOpts) {
          this.options.push(new VocabOption(opt));
        }
      }
    }

    if (options['options']) {
      for (const opt of options['options']) {
        this.options.push(new VocabOption(opt));
      }
    }

    if (options['vocabref']) {
      this.vocabref = options['vocabref'];
    }

    if (options['vocab-from-bind']) {
      this.vocabFromBind = options['vocab-from-bind'];
    }

  }

  public override convertValue(value) {
    // convert value to numeric if it's not null
    if (!this.multiple) {
      return (value === null || value == '') ? value : +value;
    } else {

      // convert to [{id, label}]
      let converted = [];
      if (value) {
        if (Array.isArray(value)) {
          for (const val of value) {
            let label = '';
            let id = parseInt(val);
            if (isNaN(id)) {
              id = val;
            }
            if (val.hasOwnProperty('label')) {
              label = val['label'];
            }
            if (val.hasOwnProperty('id')) {
              id = parseInt(val['id']);
              if (isNaN(id)) {
                id = val['id'];
              }
            }
            converted.push({ id, label });
          }
        } else {
          let label = '';
          let id = +value;
          if (value.hasOwnProperty('label')) {
            label = value['label'];
          }
          if (value.hasOwnProperty('id')) {
            id = parseInt(value['id']);
            if (isNaN(id)) {
              id = value['id'];
            }
          }
          converted.push({ id, label });
        }
      }
      return converted;
    }
  }

  public vocabRefOptions() {
    const options = [];
    if (this.vocabref) {
      const vocab = this.dataService.getItem(this.vocabref);
      if (this.vocabularyService.getVocabularyById(vocab)) {
        const vocabOpts = this.vocabularyService.getVocabularyById(vocab).options;
        for (const opt of vocabOpts) {
          options.push(new VocabOption(opt));
        }
      }
    }
    return options;
  }

  public vocabFromBindOptions() {
    const options = [];
    if (this.vocabFromBind) {
      const vocabData = this.dataService.getItem(this.vocabFromBind);
      for (const opt of vocabData) {
        options.push(new VocabOption(opt));
      }
    }
    return options;
  }

  
}
