import { Pipe, PipeTransform, OnDestroy } from '@angular/core';
import { RenderableBase } from '../Models/renderable-base';
import { ModelPropertyService } from '../Services/modelproperty.service';
import { DataService } from '../Services/data.service';
import { Observable } from 'rxjs';

/*
 * Is this renderable relevant?
 *   bind | isRelevant
 *
 * Check whether the field bind is relevant. This checks all
 * rules of all bound properties. If one says 'not relevant',
 * is leading. Defaults to true.
 *
 * Also: recursively checks all subitems of a group (if it has no bind)
 * different rule applies here: if one of the children is relevant then
 * then group is relevant. Defaults to false.
 */
@Pipe({ name: 'wsIsRelevant', pure: true })
export class RelevantPipe implements PipeTransform, OnDestroy {
  private subscription = null;

  constructor(
    private modelPropertyService: ModelPropertyService,
    private dataService: DataService,
  ) {}

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public transform(renderable: RenderableBase<any>): Observable<string> {
    return Observable.create((observer) => {
      this.subscription = this.dataService.dataRefreshed.subscribe((_) => {
        observer.next(this.modelPropertyService.isRelevant(renderable));
      });
    });
  }
}
