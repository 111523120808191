import { Injectable } from '@angular/core';
@Injectable()
export class Utils {
  public static maskedEval(scr: string, context: any) {
    // set up an object to serve as the context for the code
    // being evaluated.

    const mask: any = {};
    // mask global properties
    for (let p in this) {
      if (this.hasOwnProperty(p)) {
        mask[p] = undefined;
      }
    }

    // mask['sample'] = (arr, num) => {
    //   let result = []
    //   for (let i=0; i < num; i += 1) {
    //     let rnd = Math.floor(Math.random() * arr.length);
    //     result.push(arr.splice(rnd, 1)[0]);
    //   }
    //   return result;
    // }

    // merge the context attributes
    for (let p in context) {
      if (context.hasOwnProperty(p)) {
        mask[p] = context[p];
      }
    }

    // execute script in private context
    return new Function(`with(this) { return ${scr} }`).call(mask);
  }

  public static guid() {
    const s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    };
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

  public static querySelectorInShadowRoots(root: any, selector: string): any {
    if (root.querySelector(selector)) {
      return root.querySelector(selector);
    }
    var allNodes = root.querySelectorAll('*');
    for (var i = 0; i < allNodes.length; i++) {
      if (allNodes[i].shadowRoot) {
        return this.querySelectorInShadowRoots(allNodes[i].shadowRoot, selector);
      }
    }
  }

  public static convertToBoolean(value) {
    return value === '0' || value === 'false' ? false : !!value;
  }

  formatThousandsWithRounding(n: number, dp: number, thousands: string, decimal: string) {
    let w: number = parseFloat(n.toFixed(dp)),
      k: number = w | 0,
      b = n < 0 ? 1 : 0,
      u = Math.abs(w - k),
      d = ('' + u.toFixed(dp)).substr(2, dp),
      s = '' + k,
      i = s.length,
      r = '';
    while ((i -= 3) > b) {
      r = thousands + s.substr(i, 3) + r;
    }
    return s.substr(0, i + 3) + r + (d ? decimal + d : '');
  }

  public static formatCurrencyIntl(value, { symbol, locale }) {
    const formattedLocale = locale.replace('_', '-');

    // Always use 'EUR' as the currency code for formatting
    const formatter = new Intl.NumberFormat(formattedLocale, {
      style: 'currency',
      currency: 'EUR', // Use EUR as a placeholder
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,      
    });

    // Format the value using EUR, then replace '€' with the requested symbol
    let formattedValue = formatter.format(value);
    if (formattedValue.startsWith('€')) {
      formattedValue = formattedValue.replace(/€\s*/, `${symbol}&nbsp;`);
    } else {
      formattedValue = formattedValue.replace(/\s*€/, `&nbsp;${symbol}`);
    }
    
    return formattedValue;
  }

}
