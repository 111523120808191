import { Component, OnInit  } from '@angular/core';
import { PageGroupService } from '../Services/pagegroup.service';
import { GlobalService } from '../Services/global.service';
import { BackendService } from '../Services/backend.service';
import { DataService } from '../Services/data.service';
import { SurveyService } from '../Services/survey.service';
import { SurveyComponent } from './survey.component';

@Component({
  selector: 'app-ws-offline-restart',
  templateUrl: '../Templates/offline-restart.component.html',
  // styleUrls: ['../Styles/survey.component.scss']
})
export class OfflineRestartComponent {

  constructor(
    public globals: GlobalService,
    private pgs: PageGroupService,
    private backendService: BackendService,
    private dataService: DataService,
    private surveyService: SurveyService,
    private sc: SurveyComponent
  ) {
  }

  public allowRestart(): boolean {
    return this.pgs.allowRestart();
  }

  public isLastPage() {
    return this.pgs.isLastPage();
  }

  public startNewSurvey() {
    this.dataService.restartSurvey();
    this.surveyService.finishInitializing();
  }

}
