import { Injectable } from '@angular/core';
import { Translations } from '../Models/translations';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  public surveyId: string = null;
  public locale: string = null;
  public displayLocale: string = null;
  public translationSuffix: string = null;
  public referrer: string = null;
  public referrer2: string = null;
  public DEBUG: boolean = false;
  public OFFLINE: boolean = environment.OFFLINE;
  public nothingrequired: boolean = false;
  // public currencyProperties = null;

  private _globalTranslations: Translations = null;

  // subscribers can find out if the value has changed
  public globalTranslationsChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  // subscribers can find out if the value has changed
  public displayLocaleChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  setDisplayLocale(value: string) {
    this.displayLocale = value;
    this.displayLocaleChanged.next(true);
  }

  getDisplayLocale(): string {
    return this.displayLocale;
  }

  setGlobalTranslations(translations: Translations) {
    this._globalTranslations = translations;
    this.globalTranslationsChanged.next(true);
  }
  getGlobalTranslations(): Translations {
    return this._globalTranslations;
  }
}
