import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { RenderableBase } from '../Models/renderable-base';
import { RenderableFlowGroup } from '../Models/renderable-flowgroup';
import { CardGroupComponent } from './cardgroup.component';

@Component({
  selector: 'app-ws-flowgroup',
  templateUrl: '../Templates/flowgroup.component.html',
  styleUrls: ['../Styles/flowgroup.component.scss'],
})
export class FlowGroupComponent {
  private _renderable: RenderableFlowGroup;
  @Input()
  set renderable(item: RenderableBase<any>) {
    this._renderable = item as RenderableFlowGroup;
  }
  get renderable(): RenderableFlowGroup {
    return this._renderable;
  }

  @Input() public renderables: RenderableBase<any>[];

  @Input() public form: AbstractControl<any, any>;

  @Input() public parentCardGroup: CardGroupComponent;

  @Output() activeStateChanged = new EventEmitter<[RenderableFlowGroup, boolean]>();
  @Input()
  set activeState(value: boolean) {
    if (this._renderable) {
      this.activeStateChanged.emit([this._renderable, value]);
    }
  }

  @Output() nextTab = new EventEmitter<void>();
  nextItem(event: any) {
    this.nextTab.emit();
  }

  trackBySubrenderables(index: number, renderable: RenderableBase<any>): string {
    return renderable.id;
  }
}
