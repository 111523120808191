<div *ngIf="!form.controls[renderable.id].valid && form.controls[renderable.id].touched && renderable.type != 'hidden'"
     class="alert alert-warning">

  <span *ngIf="form.controls[renderable.id].errors['required']">
    {{ (renderable.alert || 'Answering this question is required')| wsTrans: 'alert_required'|async }}
  </span>

  <ng-container *ngIf="!form.controls[renderable.id].errors['required']">

    <span *ngIf="form.controls[renderable.id].errors['datatype']">
      {{'Numbers only'|wsTrans: 'alert_numbers_only'|async}}
    </span>

    <span *ngIf="!form.controls[renderable.id].errors['datatype']">
      <ng-container *ngIf="renderable.alert">
        {{ renderable.alert | wsTrans: renderable.id + '_alert'|async }}
      </ng-container>
      <ng-container *ngIf="!renderable.alert">
        {{'Please enter a valid answer'|wsTrans: 'alert_generic_constraint'|async}}
      </ng-container>
    </span>

  </ng-container>

</div>
