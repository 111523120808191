import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root',
})
export class Logger {

    constructor(private globals: GlobalService) { }

    public debug(msg: any) {
      if (this.globals.DEBUG) {
        console.log(msg);
      }
    }

    public info(msg: any) {
      if (this.globals.DEBUG) {
        console.log(msg);
      }
    }

    public error(msg: any) {
      console.error(msg);
    }

    public warn(msg: any) {
      console.warn(msg);
    }
}
