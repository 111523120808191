import { Component, Input } from '@angular/core';
import { GlobalService } from '../Services/global.service';
import { UntypedFormGroup } from '@angular/forms';
import { PageGroupComponent } from '../Components/pagegroup.component';

@Component({
  selector: 'app-ws-navbar',
  templateUrl: '../Templates/navbar.component.html',
  styleUrls: ['../Styles/navbar.component.scss'],
})
export class NavbarComponent {

  @Input() public formGroup: UntypedFormGroup;

  constructor(
    public pgc: PageGroupComponent,
    public globals: GlobalService
  ) {}

  public async showNavbar() {

    return this.globals.OFFLINE ||
      (await this.pgc.isLastPage() && this.pgc.allowPreviousPage()) ||
      (await this.pgc.isLastPage() && this.pgc.allowRestart()) ||
      (await this.pgc.hasPreviousPage() && !(await this.pgc.isLastPage())) ||
      (await this.pgc.hasNextPage() && !(await this.pgc.isSecondLastPage())) ||
      await this.pgc.isSecondLastPage();
  }

  public getPageStatus() {
    let result = '';
    if (this.formGroup) {
      result = `state-${this.formGroup.status}`;
    }
    return result.toLowerCase();
  }

}
