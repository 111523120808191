import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { RenderableBase } from '../Models/renderable-base';
import { RenderableCardGroup } from '../Models/renderable-cardgroup';
import { ExternalCommunicationService } from '../Services/external.service';
import { RenderableFlowGroup } from '../Models/renderable-flowgroup';

@Component({
  selector: 'ws-cardgroup',
  templateUrl: '../Templates/cardgroup.component.html',
  styleUrls: ['../Styles/cardgroup.component.scss'],
})
export class CardGroupComponent {
  @Input() public renderable: RenderableCardGroup;
  @Input() public renderables: RenderableBase<any>[];
  private _form: FormGroup<any>;
  @Input()
  set form(control: AbstractControl) {
    this._form = control as FormGroup<any>;
  }
  get form(): FormGroup<any> {
    return this._form;
  }

  public activeId: string;

  @ViewChild('tabContainer') tabContainer: ElementRef;
  @ViewChild('nav') nav: NgbNav;

  constructor(private extCommService: ExternalCommunicationService) { }
  
  handleActiveStateChanged(payload: [RenderableFlowGroup, boolean]) {
    if (payload[1] && this.nav) {
      this.nav.select(payload[0].id)
    }
  }

  selectNextTab() {

    const currentIndex = this.renderables.findIndex(renderable => renderable.id === this.activeId);
    const nextIndex = (currentIndex + 1) % this.renderables.length;
    const nextRenderable = this.renderables[nextIndex];

    if (nextIndex === 0) {
      // send generic event to outside world

      let payload = {
        eventType: 'nextTabSelected',
        payload: {
          currentTabIdx: currentIndex,
          nextTabIdx: nextIndex,
        },
      };
      this.extCommService.emitGenericEvent(JSON.stringify(payload));
    }
    this.activeId = nextRenderable.id;
  }

  accordionClicked(renderable: RenderableFlowGroup) {
    this.extCommService.emitGenericEvent(JSON.stringify([
      'switch-accordion', {
        id: renderable.id,
        bind: renderable.bind
      }]));
  }

  tabClicked(renderable: RenderableFlowGroup) {
    this.extCommService.emitGenericEvent(JSON.stringify([
      'switch-tab', {
        id: renderable.id,
        bind: renderable.bind
      }]));
  }

  trackBySubrenderables(index: number, renderable: RenderableBase<any>): string {
    return renderable.id;
  }
}
