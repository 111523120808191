import { Component, Input } from '@angular/core';
import { RenderableDate } from '../Models/renderable-date';
import { BaseFormControlComponent } from '../Components/base-form-control.component';
import { RenderableBase } from '../Models/renderable-base';

@Component({
  selector: 'app-ws-date',
  templateUrl: '../Templates/date.component.html',
})
export class DateComponent extends BaseFormControlComponent {
  protected override _renderable: RenderableDate;
  @Input()
  public override get renderable(): RenderableDate {
    return this._renderable;
  }
  public override set renderable(value: RenderableBase<any>) {
    this._renderable = value as RenderableDate;
  }
}
