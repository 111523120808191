import { Pipe, PipeTransform, OnDestroy } from '@angular/core';
import { SurveyService } from '../Services/survey.service';
import { GlobalService } from '../Services/global.service';
import { Survey } from '../Models/survey';
import { Translations } from '../Models/translations';
import { Observable, combineLatest, Subscription } from 'rxjs';

/**
 * Translate a given string into the language given in locale of Survey.
 * Takes a translatable string and an id of the Renderable in which the
 * string is used.
 *
 * Returns the translated string or the original if none was found.
 */
@Pipe({ name: 'wsTrans', pure: true })
export class TransPipe implements PipeTransform, OnDestroy {
  private survey: Survey;
  private globalTranslations: Translations;

  private subscription = null;

  constructor(private surveyService: SurveyService, private globals: GlobalService) {}

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public transform(label: string, id: string, bind?: string): Observable<string> {
    return new Observable((observer) => {
      this.subscription = combineLatest([
        this.surveyService.survey,
        this.globals.globalTranslationsChanged,
        this.globals.displayLocaleChanged,
      ]).subscribe(([survey]) => {
        if (survey) {
          this.survey = survey;
          let result = { label: label, master: label };

          id = `${id}`;

          let extraLabelIdKey = null;
          let extraLabelBindKey = null;
          let extraIdKey = null;
          let extraBindKey = null;

          if (this.globals.translationSuffix) {
            extraLabelIdKey = `${id}_label${this.globals.translationSuffix}`;
            extraIdKey = `${id}${this.globals.translationSuffix}`;
            if (bind) {
              extraLabelBindKey = `${bind}_label${this.globals.translationSuffix}`;
              extraBindKey = `${bind}${this.globals.translationSuffix}`;
            }
          }

          let ifExistsId = '';
          if (id.startsWith('__if_exists__')) {
            ifExistsId = id.replace(/^__if_exists__/, '');
          }

          if (this.globals.getGlobalTranslations() && id in this.globals.getGlobalTranslations()) {
            result = this.globals.getGlobalTranslations()[id];
          } else if (
            this.globals.getGlobalTranslations() &&
            label in this.globals.getGlobalTranslations()
          ) {
            result = this.globals.getGlobalTranslations()[label];
          } else if (extraLabelIdKey && this.survey['translations'].hasOwnProperty(extraLabelIdKey)) {
            result = this.survey['translations'][extraLabelIdKey];
          } else if (extraLabelBindKey && this.survey['translations'].hasOwnProperty(extraLabelBindKey)) {
            result = this.survey['translations'][extraLabelBindKey];
          } else if (extraIdKey && this.survey['translations'].hasOwnProperty(extraIdKey)) {
            result = this.survey['translations'][extraIdKey];
          } else if (extraBindKey && this.survey['translations'].hasOwnProperty(extraBindKey)) {
            result = this.survey['translations'][extraBindKey];
          } else if (this.survey['translations'].hasOwnProperty(id + '_label')) {
            result = this.survey['translations'][id + '_label'];          
          } else if (bind && this.survey['translations'].hasOwnProperty(bind + '_label')) {
            result = this.survey['translations'][bind + '_label'];
          } else if (ifExistsId && this.survey['translations'].hasOwnProperty(ifExistsId) && this.survey['translations'][ifExistsId]) {
              result = this.survey['translations'][ifExistsId];
          } else if (id && this.survey['translations'].hasOwnProperty(id)) {
            result = this.survey['translations'][id];
          }          
          else if (bind && this.survey['translations'].hasOwnProperty(bind)) {
            result = this.survey['translations'][bind];
          } else if (this.survey['translations'].hasOwnProperty(label)) {
            result = this.survey['translations'][label];
          }

          if (this.globals.getDisplayLocale() === 'MASTER') {
            observer.next(result['master']);
          } else {
            observer.next(result['label']);
          }
        } else {
          // survey is not loaded yet.. can't translate label
          observer.next(label);
        }
      });
    });
  }
}
