import { Component, Input, OnInit } from '@angular/core';
import { RenderableSearchtree } from '../Models/renderable-searchtree';
import { IdLabel } from '../Models/idlabel';
import { SurveyComponent } from './survey.component';
import { AbstractControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { Logger } from '../Services/logger.service';
import { ChangeDetectorRef } from '@angular/core';
import { GlobalService } from '../Services/global.service';
import { DataService } from '../Services/data.service';
import { RemoveDiacriticsPipe } from '../Pipes/diacritics.pipe';
import { debounceTime } from 'rxjs/operators';
import { distinctUntilChanged } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { BaseFormControlComponent } from './base-form-control.component';
import { SurveyService, WisApiService } from '../Services';
import { RenderableBase } from '../Models/renderable-base';

interface LabelId {
  label: string;
  noDiacriticsLabel: string;
  id: any;
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-ws-searchtree',
  templateUrl: '../Templates/searchtree.component.html',
  styleUrls: ['../Styles/searchtree.component.scss'],
})
export class SearchtreeComponent extends BaseFormControlComponent {
  // @Input() public renderable: RenderableBase<any>;

  protected override _renderable: RenderableSearchtree;
  @Input()
  override set renderable(control: RenderableBase<any>) {
    this._renderable = control as RenderableSearchtree;
  }
  override get renderable(): RenderableSearchtree {
    return this._renderable;
  }


  // @Input() public form: UntypedFormGroup;
  protected override _form: FormGroup<any>;
  @Input()
  override set form(control: AbstractControl) {
    this._form = control as FormGroup<any>;
  }
  override get form(): FormGroup<any> {
    return this._form;
  }


  public searchtree: {};
  public rootId: String;
  public completeSearchtree: {};
  public flatSearchtree: Array<{}>;
  public specialItems: Array<{}>;
  public toggleWidget = true;
  public showOther = false;
  protected diacriticsPipe = new RemoveDiacriticsPipe();
  private _searchtreesSubscription = null;
  private _dataRefreshedSubscription = null;

  constructor(
    private globals: GlobalService,
    private wisApiService: WisApiService,
    private dataService: DataService,
    private logger: Logger,
    private cdr: ChangeDetectorRef,
    surveyService: SurveyService
  ) {
    super(surveyService);
  }

  public formatter = (x: { label: string }) => ''; // x.label;

  public widgetShouldShow(): boolean {

    if (this.renderable.readonly) {
      return false;
    }

    let result = true;
    const value = this.form.controls[this.renderable.id].value;

    if (this.renderable.multiple && this.renderable.max_values) {
      if (value && Array.isArray(value)) {
        result = value.length < this.renderable.max_values;
      }
      if (!value) {
        result = true;
      }
    }

    if (!this.renderable.multiple) {
      result = !value;
    }

    return result;
  }

  public search = (text$: Observable<string>) => {
    return text$
      .pipe(debounceTime(200))
      .pipe(distinctUntilChanged())
      .pipe(
        map((term) =>
          term === ''
            ? []
            : this.flatSearchtree
              .filter((v) => {
                return (
                  new RegExp(term, 'i').test(v['noDiacriticsLabel']) ||
                  new RegExp(term, 'i').test(v['label']) ||
                  new RegExp(term, 'i').test(v['id'])
                );
              })
              .sort((a, b) => {
                // search in the tree, use a simple weight algorithm
                // start of sentence is +2, start of word is +1
                const startWordRE = new RegExp(' +' + term, 'i');
                const startRE = new RegExp('^' + term, 'i');

                let aScore = 0;
                let bScore = 0;

                // first check if match is on diacritics, which is more points
                // todo: not sure about this 'algorithm'
                bScore += startRE.test(b['label']) ? 2.1 : 0;
                bScore += startWordRE.test(b['label']) ? 1.1 : 0;
                if (!bScore) {
                  bScore += startRE.test(b['noDiacriticsLabel']) ? 2.0 : 0;
                  bScore += startWordRE.test(b['noDiacriticsLabel']) ? 1.0 : 0;
                }
                aScore += startRE.test(a['label']) ? 2.1 : 0;
                aScore += startWordRE.test(a['label']) ? 1.1 : 0;
                if (!aScore) {
                  aScore += startRE.test(a['noDiacriticsLabel']) ? 2.0 : 0;
                  aScore += startWordRE.test(a['noDiacriticsLabel']) ? 1.0 : 0;
                }

                if (aScore === bScore) {
                  // compare alphabetical order
                  return a['noDiacriticsLabel'].localeCompare(b['noDiacriticsLabel']);
                }
                return bScore > aScore ? 1 : -1;
              })
          // limit the amount of search results.. as by demand, don't limit
          // .slice(0, 10),
        ),
      );
  };

  public selectedItem(item) {
    this._setValue({ id: item.item.id, label: null });
  }

  setOther(value, event) {
    this.showOther = false;
    this._setValue({ id: this.renderable.other_value, label: value });
    event.preventDefault();
    return false;
  }

  isOtherValue(value) {
    return value && this.renderable.other_value === value.id;
  }

  private createSearchtreeStructure() {
    // make sub searchtree based on rootRef (if present)
    const rootRef = this.renderable['rootref'];
    if (rootRef) {
      this.rootId = String(this.dataService.getItem(rootRef));
    }

    // for now we assume that the rootRef value should always be present in the 1st level
    let rootNode = this.completeSearchtree;
    if (this.completeSearchtree && rootRef) {
      for (const child of this.completeSearchtree['children']) {
        if (child.id === this.rootId) {
          rootNode = child;
          break;
        }
      }
    }
    this.searchtree = rootNode;

    // TODO: read the leavesOnly from the searchtree XML options
    const leavesOnly = !this.renderable.nodes_selectable;
    this.flatSearchtree = this.flattenSearchtree(this.searchtree, [], leavesOnly);
    this.specialItems = this.getSpecialItems(this.completeSearchtree);
    this.cdr.markForCheck();
  }

  public override ngOnDestroy() {
    super.ngOnDestroy();

    if (this._searchtreesSubscription) {
      this._searchtreesSubscription.unsubscribe();
    }
    if (this._dataRefreshedSubscription) {
      this._dataRefreshedSubscription.ubscubscribe();
    }
  }

  public override ngOnInit() {
    super.ngOnInit();

    // initialize searchtree
    this.wisApiService.initSearchtree(
      this.renderable['searchtreeId'],
      this.renderable.locale || this.globals.locale,
      this.globals.getDisplayLocale(),
      this.renderable['optionref'] || null,
      this.renderable['initial'] || null,
      this.renderable['ignoremissingtranslations'],
      !this.renderable.locale
    );

    // Subscribe to full list of searchtrees
    this._searchtreesSubscription = this.wisApiService.searchtrees.subscribe(
      (result) => {
        setTimeout(() => {
          const searchTreeKey = this.wisApiService.getSearchTreeKey(
            this.renderable['searchtreeId'],
            this.renderable.locale || this.globals.locale,
            this.globals.getDisplayLocale(),
            this.renderable['optionref'] || null,
            this.renderable['initial'] || null,
            this.renderable['ignoremissingtranslations'],
          );

          this.completeSearchtree = result[searchTreeKey];
          this.searchtree = {};

          if (this.completeSearchtree) {
            this.createSearchtreeStructure();
          }

        }, 500);
      },
      (error) => {
        this.logger.error('error fetching searchtree');
      },
    );

    // subscribe to data changes to see if rootId has changed
    const rootRef = this.renderable['rootref'];
    if (rootRef) {
      this._dataRefreshedSubscription = this.dataService.dataRefreshed.subscribe(() => {
        const newRootId = this.dataService.getItem(rootRef);
        if (newRootId !== this.rootId) {
          this.createSearchtreeStructure();
        }
      });
    }
  }

  /*
   * make a simple array of all items in the tree.
   */
  public flattenSearchtree(
    st: {},
    result: LabelId[],
    leavesOnly,
    parentName = '',
  ): LabelId[] {
    for (const child of st['children']) {
      if (child['children'].length > 0) {
        const parentLbl =
          child['translations'][this.globals.getDisplayLocale()] || child['translations']['MASTER'];
        this.flattenSearchtree(child, result, leavesOnly, parentLbl);
      }
      if (!leavesOnly || child.leaf) {
        let label =
          child['translations'][this.globals.getDisplayLocale()] || child['translations']['MASTER'];

        const simpleLabel = '' + label; // force to string
        label = '' + label; // force to string

        if (parentName) {
          label = `${label} (${parentName})`;
        }
        const item = {
          label,
          id: child['id'],
          noDiacriticsLabel: this.diacriticsPipe.transform(label),
        };

        // check if item is already in result array. We compare the 'id'
        const foundIdx = result.findIndex((el) => {
          return el.id === item.id;
        });
        if (foundIdx === -1) {
          result.push(item);
        } else {
          // item is present in multiple subtrees (more than one parent)
          // so we don't use the parentname in the label
          result[foundIdx]['label'] = simpleLabel;
        }
      }
    }
    return result;
  }

  public labelForKey(key: number): string {
    let label = key;
    if (this.flatSearchtree) {
      for (const row of this.flatSearchtree) {
        if (row['id'] === String(key)) {
          label = row['label'];
        }
      }
    }
    return label.toString();
  }

  public labelForValue(value: IdLabel): string {
    if (value.id === this.renderable.other_value) {
      return value.label;
    }
    return this.labelForKey(value.id);
  }

  public removeValue(idx, value) {
    const items = this.form.controls[this.renderable.id].value;
    if (!Array.isArray(items)) {
      this.logger.error('value is not an array');
    }

    const newVal = items.slice(0, idx).concat(items.slice(idx + 1, items.length));
    this.form.controls[this.renderable.id].setValue(newVal);
    return false;
  }

  public onResetClick(event: any) {
    event.preventDefault();
    if (this.form.controls['st-' + this.renderable.id]) {
      this.form.controls['st-' + this.renderable.id].reset();
    }
    this.form.controls[this.renderable.id].reset();
    this.form.controls[this.renderable.id].setValue(null, { emitEvent: false });
  }

  /**
   * Get notified of click events
   * @param child of clicked item
   */
  public onNotify(child: any) {
    this._setValue({ id: child.id, label: null });
  }

  private _setValue(newValue: any) {
    let value = this.form.controls[this.renderable.id].value;
    if (this.renderable.multiple) {
      if (value && !Array.isArray(value)) {
        this.logger.error('searchtree value is not an array');
      }
      if (!value) {
        value = [];
      }
    }

    if (this.renderable.multiple) {
      if (newValue) {
        if (newValue.id === this.renderable.other_value && !newValue.label) {
          this.showOther = true;
          return;
        }

        value.push(newValue);
        this.toggleWidget = false; // hide tree
      }
    } else {
      value = newValue.id;
    }

    this.form.controls[this.renderable.id].setValue(value);
    this.form.controls[this.renderable.id].markAsTouched();
  }

  /* find all 'special' items which are negative ('I prefer not to say' or
   * have special numbers like 999 ('Company is not in the list')
   * it should be on the first level
   */
  protected getSpecialItems(st: {}) {
    const specialItems: Array<{}> = [];

    for (const child of st['children']) {
      if (child.leaf && (child['id'] <= 0 || child['id'].toString().match(/^9{3}9*$/))) {
        specialItems.push(child);
        child['children'] = []; // we don't want this item to have children
      }
    }
    return specialItems;
  }

  trackByOptions(index: number, option): string {
    return option.id;
  }
}
