import { Component, HostListener } from '@angular/core';
import { WindowRef } from '../Utils/windowref';
import { GlobalService } from '../Services/global.service';
import { LocalStorageService } from '../Services/local-storage.service';
import { environment } from '../../environments/environment';

const PERSIST_PREFIX = 'persist::';

@Component({
  selector: 'app-root',
  templateUrl: '../Templates/loading.component.html',
  // styleUrls: ['../Styles/survey.component.scss'],
})
export class LoadingComponent {
  private settingsStorage: LocalStorageService;
  private persistentStorage: LocalStorageService;
  public locale: string;
  public displayLocale: string;
  public surveyId: string;
  public referrer: string;
  public referrer2: string;
  public sessionId: string;
  public ready: boolean;
  public queryParams: {};

  @HostListener('window:beforeunload', ['$event'])
  confirmLeave($event) {
    if (this.globals.OFFLINE) {
      // note that returning a string is no longer supported in chrome
      // (anti scamming)
      $event.returnValue = '';
    }
  }

  constructor(private windowRef: WindowRef, private globals: GlobalService) {
    this.settingsStorage = new LocalStorageService();
    this.settingsStorage.setPrefix('settings::');
    this.persistentStorage = new LocalStorageService();
    this.persistentStorage.setPrefix(PERSIST_PREFIX);
    
    let searchString = '';
    if (this.windowRef.nativeWindow.location.href.indexOf('?') >= 0) {
      searchString = this.windowRef.nativeWindow.location.href.split('?')[1];
    }
    // searchString = this.windowRef.nativeWindow.location.search

    // queryParams could be passed in as a global (window) variable
    // e.g. set by in the main survey for garmen:
    // data-inject-prefilled-relevance -> window.queryParams['prefilled-relevance']
    this.queryParams = this.windowRef.nativeWindow.queryParams || {};

    if (searchString) {
      const extraParams = this.getUrlParams(searchString);
      for (const key in extraParams) {
        if (extraParams.hasOwnProperty(key)) {
          this.queryParams[key] = extraParams[key];
        }
      }
    }

    let offline = this.globals.OFFLINE || environment.OFFLINE;
    if ('offline' in this.queryParams) {
      offline = !!this.queryParams['offline'];
    }
    this.globals.OFFLINE = offline;

    const requestedOverrideLocale = this.queryParams['locale'];

    this._loadSettings();

    let pathname = this.windowRef.nativeWindow.location.pathname;
    if (pathname === '/') {
      pathname = this.windowRef.nativeWindow.location.hash;
      if (pathname.indexOf('?') >= 0) {
        pathname = pathname.split('?')[0];
      }
    }

    let requestedSurveyId = null;
    if (pathname.split('/').length >= 2) {
      requestedSurveyId = pathname.split('/')[1];

      /* if survey was explicitly set in the path, then we reset the
       * global settings (not for offline though)
       */
      if (requestedSurveyId && !this.globals.OFFLINE) {
        this.globals.surveyId = null;
        this.globals.locale = null;
        this.globals.setDisplayLocale(null);
        this.globals.referrer = null;
        this.globals.referrer2 = null;
      }
    }
    this.surveyId =
      this.windowRef.nativeWindow.wisurveyId || requestedSurveyId || this.globals.surveyId || null;

    let requestedLocale = null;
    if (pathname.split('/').length >= 3) {
      requestedLocale = pathname.split('/')[2];
    }

    this.locale =
      this.windowRef.nativeWindow.wisurveyLocale || requestedLocale || this.globals.locale || null;

    if (Object.keys(this.queryParams).length > 0) {
      this._savePersistentParams(this.queryParams);
      this._saveSettingsParams(this.queryParams);
    }

    this._loadPersistentParams();

    // a bit weird.. but the locale can be specified in the query parameter
    // as well, in that case it's actually the displayLocale :-)
    if (requestedOverrideLocale) {
      this.displayLocale = requestedOverrideLocale;
    }

    if (!this.displayLocale) {
      this.displayLocale = this.globals.getDisplayLocale() || this.locale;
    }

    if ('sessionId' in this.queryParams) {
      this.sessionId = <string>(this.queryParams['sessionId']);
    }

    if ('referrer' in this.queryParams) {
      this.referrer = <string>(this.queryParams['referrer']);
    }

    if (!this.referrer) {
      this.referrer = this.globals.referrer;
    }

    if ('referrer2' in this.queryParams) {
      this.referrer2 = <string>(this.queryParams['referrer2']);
    }

    if (!this.referrer2) {
      this.referrer2 = this.globals.referrer2;
    }

    let debug = this.globals.DEBUG;
    if ('debug' in this.queryParams) {
      debug = !!this.queryParams['debug'];
    }

    if ('nothingrequired' in this.queryParams) {
      this.globals.nothingrequired = !!this.queryParams['nothingrequired'];
    }

    this.globals.locale = this.locale;
    this.globals.setDisplayLocale(this.displayLocale);
    this.globals.surveyId = this.surveyId;
    this.globals.DEBUG = debug;
    // this.globals.OFFLINE = offline;

    this.ready = true;
  }

  public onSettingsChanged() {
    this.locale = this.globals.locale;
    this.surveyId = this.globals.surveyId;
    this.displayLocale = this.globals.getDisplayLocale();
    this.referrer = this.globals.referrer;
    this.referrer2 = this.globals.referrer2;
  }

  private getUrlParams(searchString) {
    const hashes = searchString.slice(searchString.indexOf('?') + 1).split('&');
    const params = {};
    hashes.map(hash => {
      const [key, val] = hash.split('=');
      params[key] = decodeURIComponent(val);
    });
    return params;
  }

  // save parameters that are masrked as 'persistent' to localstorage
  // this is handy when prefilling offline surveys because some parameters
  // need to persist at the start of a new survey after the previous one has ended
  private _savePersistentParams(queryParams) {
    // first clear all (old) persistent settings from localstorage
    this.persistentStorage.clear(/.*/);

    for (const key of Object.keys(queryParams)) {
      if (key.startsWith(PERSIST_PREFIX)) {
        const value = queryParams[key];
        const storageKey = key.substring(PERSIST_PREFIX.length)
        this.persistentStorage.set(storageKey, value);
      }
    }
  }

  private _saveSettingsParams(queryParams) {
    // first clear all (old) persistent settings from localstorage
    this.settingsStorage.clear(/.*/);

    for (const key of Object.keys(queryParams)) {
      if (!key.startsWith(PERSIST_PREFIX)) {
        const value = queryParams[key];
        this.settingsStorage.set(key, value);
      }
    }


  }

  private _loadPersistentParams() {
    for (const key of this.persistentStorage.keys()) {
      const value = this.persistentStorage.get(key);
      this.queryParams[key] = value;
    }
  }

  private _loadSettings() {

    for (let key of this.settingsStorage.keys()) {
      const value = this.settingsStorage.get(key);

      if (key in this.globals) {
        this.globals[key] = value;
      }
    }
  }
}
