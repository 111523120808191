import { Injectable } from '@angular/core';
import { RenderableBase } from '../Models/renderable-base';
import { RenderableCheckbox } from '../Models/renderable-checkbox';
import { RenderableRichText } from '../Models/renderable-richtext';
import { RenderableText } from '../Models/renderable-text';
import { RenderableDate } from '../Models/renderable-date';
import { RenderableHidden } from '../Models/renderable-hidden';
import { RenderableInput } from '../Models/renderable-input';
import { RenderableSelect } from '../Models/renderable-select';
import { RenderableGroup } from '../Models/renderable-group';
import { RenderableCardGroup } from '../Models/renderable-cardgroup';
import { RenderablePageGroup } from '../Models/renderable-pagegroup';
import { RenderableFlowGroup } from '../Models/renderable-flowgroup';
import { RenderableMatrixGroup } from '../Models/renderable-matrixgroup';
import { RenderableSearchtree } from '../Models/renderable-searchtree';
import { RenderableSubmit } from '../Models/renderable-submit';
import { RenderableRange } from '../Models/renderable-range';
import { BehaviorSubject } from 'rxjs';
import { Logger } from '../Services/logger.service';
import { RenderableCurrency } from '../Models/renderable-currency';

@Injectable({
  providedIn: 'root',
})
export class ViewService {
  protected _view: any;
  protected _renderables: Array<RenderableBase<any>> = [];
  public renderables: BehaviorSubject<Array<RenderableBase<any>>> = new BehaviorSubject<
    Array<RenderableBase<any>>
  >([]);

  private renderableMapping: any = {
    vocab: <string>null, // vocab is a weird exception..
    checkbox: RenderableCheckbox,
    input: RenderableInput,
    currency: RenderableCurrency,
    select: RenderableSelect,
    text: RenderableText,
    richtext: RenderableRichText,
    date: RenderableDate,
    month: RenderableDate,
    time: RenderableInput,
    hidden: RenderableHidden,
    range: RenderableRange,
    matrixgroup: RenderableMatrixGroup,
    searchtree: RenderableSearchtree,
    cardgroup: RenderableCardGroup,
    pagegroup: RenderablePageGroup,
    flowgroup: RenderableFlowGroup,
    gridgroup: RenderableFlowGroup, // we don't use grids (tables) right now
    submit: RenderableSubmit,
  };

  constructor(private logger: Logger) { }

  public destroy() {
    this._view = null;
    this._renderables = [];
    // this.renderables.complete();
  }


  public setRawView(
    view: {
      view?: {};
    } = {},
  ) {
    this._view = view;
    this.renderables.next(this.getRenderables());
  }

  public addRenderable(container: any, component: any, parent: RenderableBase<any> | null) {
    let newRenderable: any = null;
    const klass = this.renderableMapping[component.type] || null;
    if (klass) {
      newRenderable = new klass(component);
      newRenderable.parent = parent;
    }

    if (!(component.type in this.renderableMapping)) {
      this.logger.error(`no renderable for: ${component.type}`);
    }

    if (newRenderable) {
      container.push(newRenderable);
    }

    if (newRenderable && newRenderable instanceof RenderableGroup) {
      for (const compId in component['components']) {
        if (component['components'].hasOwnProperty(compId)) {
          this.addRenderable(
            newRenderable.subrenderables,
            component['components'][compId],
            newRenderable,
          );
        }
      }
    }
  }

  public getRenderables() {
    if (!this._renderables || !this._renderables.length) {
      // initialize global renderable counter. It keeps growing after a 
      // reload of the survey due to hot-reloading
      RenderableBase.resetGlobalCounter();
      let renderables: any = [];
      if (this._view) {
        for (const componentId in this._view['components']) {
          if (this._view['components'].hasOwnProperty(componentId)) {
            const component: any = this._view['components'][componentId];
            this.addRenderable(renderables, component, null);
          }
        }
      }
      this._renderables = renderables.length > 0 ? renderables : null;
    }

    return this._renderables;
  }
}
