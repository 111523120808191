import { Pipe, PipeTransform, OnDestroy } from '@angular/core';
import { DataService } from '../Services/data.service';
import { Logger } from '../Services/logger.service';
import { Utils } from '../Utils/utils';
import { ViewControlService } from '../Services/view-control.service';
import { ViewService, TranslationService } from '../Services';
import { Observable, combineLatest } from 'rxjs';
import { ExternalCommunicationService } from '../Services/external.service';

/**
 * typescript kan alleen maar interpolate met backtick strings doen
 * volgens mij, dus deze helper functie is handig om vanuit dynamische
 * strings (XML) toch placeholders te kunnen gebruiken, zoals op de thanks
 * pagina
 *
 * voorbeeld:
 * "De gekozen locale is: ${COUNTRYW_LOCALE}"
 * "of een vertaling: ${translate: submit}"
 * "of een expressie: ${expression: data['field_a'] + data['field_b']}"
 * "of een pythonic string interpolatie: {field_c}"
 *
 * Returns the interpolated string or the original if none was found.
 */
@Pipe({ name: 'wsInterpolate', pure: true })
export class InterpolatePipe implements PipeTransform, OnDestroy {
  private subscription = null;

  constructor(
    private logger: Logger,
    private dataService: DataService,
    private viewControlService: ViewControlService,
    private extCommService: ExternalCommunicationService,
    private viewService: ViewService,
    private translationService: TranslationService,
  ) {}

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public transform(value: string): Observable<string> {
    /* now this is a bit more complex than it seems.. we have some special
     * cases with recursive stuff e.g. the following:
     * ${translate: Why the hell is this so #[[complex]] man?}
     */

    return new Observable((observer) => {
      this.subscription = combineLatest([
        this.dataService.dataRefreshed,
        this.extCommService.externalContextListner(),
      ]).subscribe(([combined, extData]) => {
        if (!value) {
          observer.next(value);
        } else {
          const context = this.dataService.getData();
          const renderables = this.viewService.getRenderables();
          const renderablesByBind = this.viewControlService.getRenderablesByBind(renderables);
          let replaced = value;

          replaced = replaced.replace(/\$\{(.*?)\}/g, (match, expr) => {
            return `{${expr}}`;
          });

          // replaced = replaced.replace(/\$\{(.*?)\}/g, (match, expr) => {
          //   if (expr in context) {
          //     const result = context[expr];
          //     if (result == null) {
          //       return '';
          //     }
          //     return result;
          //   }

          replaced = replaced.replace(
            /\{translate: *(.*?)(::.*?)?\}/g,
            (match, transKey, transString) => {
              if (transString) {
                transString = transString.substring(2); // strip 2 colons
              } else {
                transString = transKey;
              }
              return this.translationService.translate(transString, transKey);
            },
          );

          replaced = replaced.replace(/\{expression: *(.*?)\}/g, (match, expr) => {
            let result: any = '';
            try {
              result = Utils.maskedEval(expr, { data: context, extData });
            } catch (e) {
              this.logger.error(`Error in interpolate expression ${expr}`);
            }
            if (result === false || result === null || result === undefined) {
              return '';
            }
            return result;
          });

          // replace lexical expression
          replaced = replaced.replace(/\{(.*?):lexicalValue\}/g, (_match, expr) => {
            const renderable = renderablesByBind[expr];
            return renderable && renderable.lexicalValue();
          });

          // check if pythonic interpolation
          replaced = replaced.replace(/\{(.*?)\}/g, (_match, expr) => {
            if (expr in context) {
              const result = context[expr];
              if (result == null) {
                return '';
              }
              return result;
            }
          });

          replaced = replaced.replace(/\#\[\[(.*?)\]\]/g, (_match, expr) => {
            if (expr in context) {
              const result = context[expr];
              if (result == null) {
                return '';
              }
              return result;
            } else {
              const exprRE = /^expression:(.*?)$/;
              const foundExpr = exprRE.exec(expr);
              if (foundExpr) {
                try {
                  let res = Utils.maskedEval(foundExpr[1], { data: context, extData });
                  if (res === false || res === null || res === undefined) {
                    res = '';
                  }
                  return res;
                } catch (e) {
                  this.logger.error(`Error in interpolate expression ${expr}`);
                }
              }
            }
          });

          observer.next(replaced);
        }
      });
    });
  }
}
