import { Injectable } from '@angular/core';
import { ModelProperty } from '../Models/modelproperty';
import { Utils } from '../Utils/utils';
import { Logger } from './logger.service';
import { DataService } from './data.service';
import { RenderableBase } from '../Models/renderable-base';
import { ModelService } from './model.service';

@Injectable({
  providedIn: 'root',
})
export class ModelPropertyService {

  constructor(private modelService: ModelService, private dataService: DataService, private logger: Logger) {}

  public isRequired(bindingId: string): boolean {
    // check ALL properties for this bind. If one is required
    // then this is considered a required field (bind)

    let required: boolean = false;
    let data = this.dataService.getData();

    // array of props
    let props = this.modelService.getProperties()[bindingId];
    // when one of modelprops is found to be required, consider it required.
    for (let prop in props) {
      if (props[prop]['required'] !== undefined) {
        let expression = props[prop]['required'];
        if (expression !== '0' && expression !== 'false') {
          if (expression === 'true') {
            required = true;
          } else {
            required = Utils.maskedEval(props[prop]['required'], { data });
          }
          if (required) {
            break; // no need to look further
          }
        }
      }
    }

    return required;
  }

  /*
   * Is this renderable relevant?
   *   bind | isRelevant
   *
   * Check whether the field bind is relevant. This checks all
   * rules of all bound properties. If one says 'not relevant',
   * is leading. Defaults to true.
   *
   * Also: recursively checks all subitems of a group (if it has no bind)
   * different rule applies here: if one of the children is relevant then
   * then group is relevant. Defaults to false.
   */
  public isRelevant(renderable: RenderableBase<any>): boolean {
    if (!renderable) {
      return false;
    }

    let binds = [];
    if (renderable.bind) {
      binds = this.modelService.getProperties()[renderable.bind] || [];
    }

    let relevant = true;

    if (binds.length !== 0) {
      // We have a bind. Check all relevance expressions for this bind
      for (let modProp of binds) {
        let relevantExpr = modProp['relevant'];
        let result: any = true;

        if (relevantExpr !== undefined) {
          const data = this.dataService.getData();
          if (data) {
            try {
              result = Utils.maskedEval(relevantExpr, { data: data });
            } catch (e) {
              this.logger.error(
                `Error in expression for bind ${modProp['bind']}: ${modProp['relevant']}`,
              );
            }
          }
        }

        if (!result) {
          // if one rule says not relevant, this item is the winner
          relevant = false;
          break;
        }
      }
    }

    // Check all children until we find a relevant one
    if (relevant && renderable.hasOwnProperty('subrenderables')) {
      let childrenRelevant = false;
      if (renderable.hasOwnProperty('subrenderables')) {
        for (let sr of renderable['subrenderables']) {
          let result = this.isRelevant(sr);
          // let result = new RelevantPipe(
          //   this.dataService, this.modelService, this.logger).transform(sr);
          if (result) {
            childrenRelevant = true;
            break;
          }
        }
      }
      relevant = childrenRelevant;
    }

    // this renderable seems relevant. But do one more check: if parent
    // is explicitly set as not relevant (e.g. a flowgroup) then this
    // renderable is also not relevant. (edge case)

    if (relevant && this.isParentExplicitlyNotRelevant(renderable)) {
      relevant = false;
    }

    return relevant;
  }

  private isParentExplicitlyNotRelevant(renderable: RenderableBase<any>): boolean {
    let parent = renderable.parent;

    if (parent) {
      let binds = [];
      if (parent.bind) {
        binds = this.modelService.getProperties()[parent.bind] || [];
      }

      if (binds.length !== 0) {
        // We have a bind. Check all relevance expressions for this bind
        for (let modProp of binds) {
          let relevantExpr = modProp['relevant'];
          let result: any = true;

          if (relevantExpr !== undefined) {
            try {
              result = Utils.maskedEval(relevantExpr, { data: this.dataService.getData() });
            } catch (e) {
              this.logger.error(
                `Error in expression for bind ${modProp['bind']}: ${modProp['relevant']}`,
              );
            }
          }

          if (!result) {
            // if one rule says not relevant, this item is the winner
            return true;
          }
        }
      }
      return this.isParentExplicitlyNotRelevant(parent);
    }
    return false;
  }
}
