<div [formGroup]="form"
     *ngIf="form && renderable.id === currentPageId" class="card card-ws page" [class]="additionalClasses">

  <div *ngIf="renderable.label || globals.DEBUG" class="card-header mt-3 mb-3">
    <h2 *ngIf="renderable.label">
    {{ renderable.label | wsTrans:(renderable.msgid || renderable.id):renderable.bind|async|wsInterpolate|async }}</h2>
    <strong *ngIf="globals.DEBUG" class="text-muted">{{renderable.type | uppercase }}</strong>
    <small *ngIf="globals.DEBUG" class="text-muted">{{ renderable.id }}</small>

    <span *ngIf="globals.DEBUG">
      <span *ngFor="let key of ['pristine', 'touched', 'dirty', 'status']">
        <small class="primary primary-primary">
          {{ key + ':' + form[key] }}
        </small>
      </span>
    </span>
  </div>

  <app-ws-navbar [formGroup]="form" *ngIf="form"></app-ws-navbar>

  <div class="card-block p-3 mb-3 rounded">
    <div *ngFor="let subrenderable of renderable.subrenderables; trackBy: trackBySubrenderables"
         class="form-row">
      <app-df-renderable [renderable]="subrenderable" [form]="form"></app-df-renderable>
    </div>
  </div>

  <app-ws-navbar [formGroup]="form" *ngIf="form"></app-ws-navbar>

</div>
