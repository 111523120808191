import { Component, OnInit, OnDestroy  } from '@angular/core';
import { MessageService } from '../Services/message.service';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-ws-messages',
  templateUrl: '../Templates/message.component.html',
  styleUrls: ['../Styles/message.component.scss']
})
export class MessageComponent implements OnInit, OnDestroy{

  public messages = [];
  private _messagesSubscriber = null;

  constructor(
    private messageService: MessageService
  ) {
  }

  public ngOnInit() {
    this._messagesSubscriber = this.messageService.messages$.subscribe((messages) => {
      this.messages = messages;
    });
  }

  public ngOnDestroy() {
    if (this._messagesSubscriber) {
      this._messagesSubscriber.unsubscribe();
    }
  }

  public dismissMessage(message: {'type', 'message'}) {
    this.messageService.dismissMessage(message);
  }

}
