export class Survey {
  public id: string;
  public filename: string;
  public version: string;
  public data: {};
  public model: {};
  public view: {};
  public locale: string;
  public css: string;
  public translations: {};
  public supported_locales: [];
  public currency: {};

  constructor(public form: Object) {

    // HB: Object.assign werkt niet goed, dan krijgt this ook
    // een 'form' property (??)
    // Object.assign(this, {xform});

    Object.keys(form).forEach(k => {
      this[k] = form[k];
    });
  }
}
