import { Component, Input, EventEmitter, Output, HostBinding } from '@angular/core';
import { IconName } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-link',
  template: `
    <section *ngIf="replaceVariables(condition) | wsInterpolate | async">
      <a
        href
        [class]="replaceVariables(class) | wsInterpolate | async"
        (click)="clicked()"
        [title]="replaceVariables(title) | wsInterpolate | async"
      >
        {{ replaceVariables(label) | wsInterpolate | async }}
        <fa-icon *ngIf="faIcon" [icon]="['fas', faIcon]"></fa-icon>
      </a>
    </section>
  `,
})
export class LinkComponent {
  @Input() bind: string = '';
  @Input() type: string = '';
  @Input() specifier: string = '';
  @Input() label: string = '';
  @Input() title: string = '';
  @Input() class: string = '';
  @Input() hostClass: string = '';
  @Input() faIcon: IconName = null;
  @Input() condition: string = '';
  @Output() output = new EventEmitter();

  @HostBinding('class') get dynamicHostClass() {
    return this.hostClass;
  }

  constructor() {}

  public replaceVariables(expression: string) {
    if (expression) {
      let replaced = expression.replace(/__bind__/g, this.bind);
      replaced = replaced.replace(/__type__/g, this.type);
      return replaced;
    }
    return expression || '';
  }

  public clicked() {
    this.output.next(this.bind);
    return false;
  }
}
