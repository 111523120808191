import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Logger } from './logger.service';
import { Survey } from '../Models/survey';
import { Translations } from '../Models/translations';
import { GlobalService } from './global.service';
import { LocalStorageService } from '../Services/local-storage.service';

import { MessageService } from './message.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { AvailableSurveys } from '../Models/available-survey';

import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class BackendService {

  public storedSurveys$: Observable<Array<{ 'data', 'pageMetaData' }>>;
  protected _storedSurveys: BehaviorSubject<Array<{ 'data', 'pageMetaData' }>> =
    new BehaviorSubject([]);

  private wsengineUrl = environment.ENGINE_URL;
  private _id: string;
  private _locale: string;
  private backendLocalStorage: LocalStorageService;
  private surveysLocalStorage: LocalStorageService;

  constructor(
    public globals: GlobalService,
    private logger: Logger,
    private messageService: MessageService,
    private http: HttpClient,
  ) {
    this.storedSurveys$ = this._storedSurveys.asObservable();
    // note: cannot check for globals.OFFLINE here, it's not yet initialized
    this.backendLocalStorage = new LocalStorageService()
    this.backendLocalStorage.setPrefix('backend::');

    this.surveysLocalStorage = new LocalStorageService()
    this.surveysLocalStorage.setPrefix('surveys::');

    let storedSurveys = this._loadStoredSurveys();
    this._storedSurveys.next(storedSurveys);
  }

  public clearLocalStorage() {
    // clear the backend local storage (not the stored surveys)
    this.backendLocalStorage.clear(/.*/);
  }

  public submitSurvey(data: {}, pageMetaData: {}, finalized: any) {
    if (this.globals.OFFLINE) {
      // store survey in localStorage array
      // let storedSurveys = this.storedSurveys();
      // if not finalized, just leave the storage to the session storage
      if (finalized) {
        let storedSurveys = this._storedSurveys.getValue();
        storedSurveys.push({ data, pageMetaData });
        this._storedSurveys.next(storedSurveys);
        this.surveysLocalStorage.set('SURVEYS', this._storedSurveys.getValue());
      }
      return this.storedSurveys$;
    } else {
      return this._postSurvey(data, pageMetaData, finalized);
    }
  };

  public async uploadStoredSurveys() {
    // iterate over the stored surveys and submit them one by one
    let storedSurveys = this._storedSurveys.getValue();
    let i = storedSurveys.length;
    while (i--) {
      let storedData = storedSurveys[i];
      try {
        await this._postSurvey(storedData['data'], storedData['pageMetaData'], true).toPromise();
        storedSurveys.splice(i, 1);  // remove last item from array
        this._storedSurveys.next(storedSurveys);  // broadcast the new state of affairs
        this.surveysLocalStorage.set('SURVEYS', storedSurveys);
      } catch (err) {
        // TODO: call Messaging Service
        this.logger.error('Error submitting survey.');
        this.messageService.showMessage(
          'error', 'Error submitting survey. Please try again later');
        return;
      }
    }
    this.messageService.showMessage('info', 'Surveys have been submitted to server');
  }

  public getGlobalTranslations(locale: string): Observable<Translations> {
    let url = `${this.wsengineUrl}translations/${locale}`;
    let found = this.backendLocalStorage.keys().indexOf(url) > -1;
    if (this.globals.OFFLINE && found) {
      let res = <Translations>this.backendLocalStorage.get(url);
      return of(res);
    }
    return this.http.get(url)
      .pipe(map((res: Response) => {
        let body = res || {};
        if (this.globals.OFFLINE) {
          this.backendLocalStorage.set(url, body);
        }
        return (body as Translations);
      }))
      .pipe(catchError(this.handleError));
  }

  public getAvailableSurveys(): Observable<AvailableSurveys> {
    let url = `${this.wsengineUrl}surveys`;
    let found = this.backendLocalStorage.keys().indexOf(url) > -1;
    if (this.globals.OFFLINE && found) {
      let res = <AvailableSurveys>this.backendLocalStorage.get(url);
      return of(res);
    }
    return this.http.get(url)
      .pipe(map((res: Response) => {
        let body = res || {};
        if (this.globals.OFFLINE) {
          this.backendLocalStorage.set(url, body);
        }
        return (body as AvailableSurveys);
      }))
      .pipe(catchError(this.handleError));
  }

  public getRemoteSurvey(id: string, locale: string, displayLocale: string): Observable<Survey> {
    this._id = id;
    this._locale = locale;
    let url = `${this.wsengineUrl}survey/${id}/${locale}/${displayLocale}`;
    if (!displayLocale) {
      // no trailing slash
      url = `${this.wsengineUrl}survey/${id}/${locale}`;
    }

    let found = this.backendLocalStorage.keys().indexOf(url) > -1;
    if (this.globals.OFFLINE && found) {
      let res = <Survey>this.backendLocalStorage.get(url);
      return of(res);
    }

    return this.http.get(url)
      .pipe(map((res: Response) => {
        let body = res || {};
        if (this.globals.OFFLINE) {
          this.backendLocalStorage.set(url, body);
        }
        return (body as Survey);
      }))
      .pipe(catchError(this.handleError));
  }

  public getSurveyData(sessionId: string): Observable<{}> {
    let url = `${this.wsengineUrl}data/${sessionId}`;

    return this.http.get(url)
      .pipe(map((res: Response) => {
        // let body = res.json() || {};
        let body = res || {};
        return body;
      }))
      .pipe(catchError(this.handleError));
  }

  protected handleError(error: Response | any) {
    // we could use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = (body as any).error || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    // console.error(errMsg);
    return throwError(errMsg);
  }

  protected _loadStoredSurveys(): Array<{ 'data', 'pageMetaData' }> {
    let storedSurveys: Array<{ 'data', 'pageMetaData' }> =
      this.surveysLocalStorage.get('SURVEYS');
    if (!storedSurveys) {
      storedSurveys = [];
    }
    return storedSurveys;
  }

  protected _postSurvey(data: {}, pageMetaData: {}, finalized: any) {
    let payload = { survey: data, pageMetaData, finalized };
    let url = `${this.wsengineUrl}survey/${this._id}/${this.globals.locale}`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    //let options = new RequestOptions({ headers });
    return this.http.post(url, payload, httpOptions)
      .pipe(catchError(this.handleError));
  }
}
