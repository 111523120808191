import { Injector } from '@angular/core';

/**
 * Used to reach Services from anywhere.
 * Construction takes place in AppModule below.
 *
 * usage: ServiceLocator.injector.get([ServiceName]
 */
export class ServiceLocator {
  public static injector: Injector;
}
