import { BehaviorSubject, Subject } from 'rxjs';

export class ExternalCommunicationService {
  private genericEvent = new Subject<string>();
  private externalContext = new BehaviorSubject<{}>({});
  private questionHeaders = new BehaviorSubject<{}[]>([]);

  emitGenericEvent(msg: string) {
    this.genericEvent.next(msg);
  }

  genericEventListener() {
    return this.genericEvent.asObservable();
  }

  emitExternalContext(ctx: {}) {
    this.externalContext.next(ctx);
  }

  externalContextListner() {
    return this.externalContext.asObservable();
  }

  emitQuestionHeaders(data: []) {
    this.questionHeaders.next(data);
  }

  questionHeadersListner() {
    return this.questionHeaders.asObservable();
  }
}
