import { Component, Input, OnDestroy } from '@angular/core';
import { AbstractControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { OnInit } from '@angular/core';
import { RenderableBase } from '../Models/renderable-base';
import { SurveyService } from '../Services/survey.service';


/*
 * Base Component for form controls like checkbox,
 * input, radio, etc.
 */
@Component({
  selector: 'app-ws-base',
  template: '',
})
export class BaseFormControlComponent implements OnInit, OnDestroy {

  protected surveyService: SurveyService;

  constructor(surveyService: SurveyService) {
    this.surveyService = surveyService;
  }

  @Input()
  protected _renderable: RenderableBase<any> | null = null;
  public get renderable(): RenderableBase<any> | null {
    return this._renderable;
  }
  public set renderable(value: RenderableBase<any> | null) {
    this._renderable = value;
  }
  // @Input() public form: UntypedFormGroup;
  protected _form: FormGroup<any> | null = null;
  @Input()
  set form(form: AbstractControl<any, any> | null) {
    this._form = (form as FormGroup<any>);
  }
  get form(): FormGroup<any> | null {
    return this._form;
  }

  public showRenderableCounters(): boolean {
    return this.surveyService.showRenderableCounters();
  }

  public modprop: any;
  private control: any;
  private dataTypeMap: any = {
    'int': 'number',
    'time': 'time',
    'float': 'number',
  };

  private getMappedDatatype(datatype: string) {
    let result: any = datatype;
    if (datatype in this.dataTypeMap) {
      result = this.dataTypeMap[datatype];
    }
    return result;
  }

  public ngOnInit() {
    const control = this.form.controls[this.renderable.id];
    this.modprop = control ? control['modprop'] : null;
    this.control = control;
    this.control.value = this.renderable.convertValue(this.control.value);
  }

  public ngOnDestroy() { }

  public getDataType() {
    let datatype = '';
    if (this.modprop) {
      for (const mp of this.modprop) {
        if (mp.datatype) {
          datatype = this.getMappedDatatype(mp.datatype);
        }
      }
    }
    return datatype;
  }

  public isValid() {
    if (this.control) {
      return this.control.valid;
    }
    return true;
  }
}
