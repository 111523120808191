<nav *ngIf="showNavbar()" class="d-block submission navbar navbar-light my-3 p-3 border rounded bg-light shadow-sm">

  <button *ngIf="pgc.hasPreviousPage() && (!pgc.isLastPage() || pgc.allowPreviousPage())"
    class="btn btn-outline-secondary nav-prev me-2" type="button" role="button" (click)="pgc.previousPage($event)">
    <fa-icon [icon]="['fas', 'angle-double-left']"></fa-icon>
  </button>

  <button *ngIf="pgc.hasNextPage()" href class="btn btn-outline-primary btn-wsnav nav-next" role="button" type="button"
    [ngClass]="getPageStatus() + ' ' + pgc.getNextButtonExtraClasses()" (click)="pgc.nextPage($event)">
    {{ pgc.getNextLabel() | wsTrans: pgc.getNextMsgId() |async }}
    <fa-icon [icon]="['fas', 'angle-double-right']"></fa-icon>
  </button>

  <app-ws-offline-restart></app-ws-offline-restart>

  <ng-container *ngIf="globals.OFFLINE">
      <span *ngIf="globals.referrer" class="badge text-bg-secondary float-end">{{ globals.referrer }}</span>
      <span *ngIf="globals.referrer2" class="badge text-bg-secondary float-end">{{ globals.referrer2 }}</span>
      <span class="badge text-bg-secondary float-end me-1">{{ globals.surveyId }}</span>
      <span class="badge text-bg-secondary float-end me-1">APP</span>
  </ng-container>

  <app-ws-messages></app-ws-messages>
</nav>