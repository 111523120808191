/*
Toggle input currency field with a display value span
*/
import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { SurveyService } from '../Services/survey.service';

@Directive({
  selector: '[appCurrencyInput]',
})
export class CurrencyInputDirective implements OnInit {
  @Input() options: any = {};

  private _input: HTMLInputElement;
  private _display: HTMLSpanElement;

  optionsDefaults = {
    prefix: '',
    suffix: '',
    thousands: ',',
    decimal: '.',
  };

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private vcr: ViewContainerRef,
    private tr: TemplateRef<void>,
    private surveyService: SurveyService
  ) { }

  ngOnInit() {
    this.vcr.createEmbeddedView(this.tr);

    this._input = this.el.nativeElement.parentNode.getElementsByTagName('input')[0];
    this._display = this.el.nativeElement.parentNode.getElementsByTagName('span')[0];
    this.renderer.setStyle(this._input, 'display', 'block');
    this.renderer.setStyle(this._display, 'display', 'none');

    this._display.addEventListener('click', (event) => {
      this.toggleInput(true);
    });

    this._input.addEventListener('blur', (event) => {
      this._display.innerHTML = this.getDisplayValue();
      this.toggleInput(false);
    });

    this._input.addEventListener('keypress', (event: KeyboardEvent) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        this._display.innerHTML = this.getDisplayValue();
        this.toggleInput(false);
      }
    });

    // show the display value after values have been preloaded
    this.surveyService.survey.subscribe((value) => {
      setTimeout(() => {
        this._display.innerHTML = this.getDisplayValue();
        this.toggleInput(false);
      }, 0);
    });

  }

  toggleInput(showInput: boolean) {
    let forReal = showInput; // should we hide input?

    const hasError =
      this._input.classList.contains('ng-invalid') ||
      !this._input.value ||
      parseFloat(this._input.value) <= 0;

    if (hasError) {
      forReal = true;
    }

    this.renderer.setStyle(this._display, 'display', forReal ? 'none' : 'block');
    this.renderer.setStyle(this._input, 'display', forReal ? 'block' : 'none');

    if (showInput && forReal) {
      this._input.focus();
    }
  }

  getOpt(name: string) {
    let result = this.options[name];
    if (result === undefined) {
      result = this.optionsDefaults[name];
    }
    if (result === undefined) {
      result = '';
    }
    return result;
  }

  formatThousandsWithRounding(n: number, dp: number, thousands: string, decimal: string) {
    let w: number = parseFloat(n.toFixed(dp)),
      k: number = w | 0,
      b = n < 0 ? 1 : 0,
      u = Math.abs(w - k),
      d = ('' + u.toFixed(dp)).substr(2, dp),
      s = '' + k,
      i = s.length,
      r = '';
    while ((i -= 3) > b) {
      r = thousands + s.substr(i, 3) + r;
    }
    return s.substr(0, i + 3) + r + (d ? decimal + d : '');
  }

  getDisplayValue() {
    const val = this.formatThousandsWithRounding(
      parseFloat(this._input.value),
      2,
      this.getOpt('thousands'),
      this.getOpt('decimal'),
    );
    return `${this.getOpt('prefix')}${val}${this.getOpt('suffix')}`;
  }
}
