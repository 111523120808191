import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { throwError, BehaviorSubject } from 'rxjs';
import { LocalStorageService } from '../Services/local-storage.service';
import { GlobalService } from './global.service';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { Logger } from './logger.service';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class WisApiService {
  private wisapiUrl = environment.WISAPI_URL;
  private lstorage: LocalStorageService;

  public searchtrees: Observable<{}>;
  private _searchtrees: BehaviorSubject<{}> = new BehaviorSubject({});
  private _loadingTrees: any = {};

  constructor(
    public globals: GlobalService,
    private http: HttpClient,
    private logger: Logger,
    private dataService: DataService,
  ) {
    // note: cannot check for globals.OFFLINE here, it's not yet initialized
    this.lstorage = new LocalStorageService();
    this.lstorage.setPrefix('wisapi::')
    this.searchtrees = this._searchtrees.asObservable();
  }

  public clearLocalStorage() {
    this.lstorage.clear(/.*/);
  }

  public getRemoteSearchtree(
    id: string,
    locale: string,
    displayLocale: string,
    discriminator?: string,
    ignoremissingtranslations?: boolean,
  ): Observable<any> {
    let url = this.wisapiUrl + id + '/' + locale;
    if (discriminator) {
      url += '/' + discriminator;
    }
    if (displayLocale) {
      url += `/translateto/${displayLocale || locale}`;
    }

    if (ignoremissingtranslations) {
      url += '?ignoremissingtranslations=1';
    }

    // first check the offline storage for this rest response
    const found = this.lstorage.keys().indexOf(url) > -1;
    if (this.globals.OFFLINE && found) {
      const res = this.lstorage.get(url);
      return of(res);
    }

    return this.http.get(url).pipe(
      map((res: any) => {
        if (this.globals.OFFLINE) {
          this.lstorage.set(url, res);
        }
        return res;
      }),
    );
    // .share()
    // .catch(this.handleError)
    //      )
  }

  public getSearchTreeKey(
    searchtreeId: string,
    locale: string,
    displayLocale: string,
    optionref?: string,
    initial?: string,
    ignoremissingtranslations?: boolean,
  ) {
    let discriminator = null;
    if (optionref) {
      discriminator = this.dataService.getItem(optionref);
    }
    if (discriminator == null && initial) {
      discriminator = initial;
    }
    if (discriminator) {
      return `${searchtreeId}#${locale}#${discriminator}#${displayLocale}#${ignoremissingtranslations}`;
    } else {
      return `${searchtreeId}#${locale}#${displayLocale}#${ignoremissingtranslations}`;
    }
  }

  /**
   * Get searchtree by id and locale:
   * this.getSearchtree('Language_for', locale);
   *
   * @param searchtreeId
   * @param locale
   * @param displayLocale
   * @param optionref?
   */
  public initSearchtree(
    searchtreeId: string,
    locale: string,
    displayLocale: string,
    optionref?: string,
    initial?: string,
    ignoremissingtranslations?: boolean,
    subscribeToLocaleChanges?: boolean
  ) {
    this._loadRemoteSearchTree(
      searchtreeId,
      locale,
      displayLocale,
      optionref,
      initial,
      ignoremissingtranslations,
    );
    // TODO: listen for changes in displayLocale (in globals?)

    let optionrefCtrlSubscription: any = null;
    let localeCtrlSubscription: any = null;

    if (subscribeToLocaleChanges) {
      this.dataService.flatData$.subscribe(async (flatdata: any) => {
        // listen for changes in optionref
        if (optionrefCtrlSubscription) {
          optionrefCtrlSubscription.unsubscribe();
        }
        if (optionref && optionref in flatdata) {
          this._loadRemoteSearchTree(
            searchtreeId,
            locale,
            displayLocale,
            optionref,
            initial,
            ignoremissingtranslations,
          );
          const ctrl = flatdata[optionref][0];
          let curVal = ctrl.value;
          optionrefCtrlSubscription = ctrl.valueChanges.subscribe((value: any) => {
            if (value != curVal) {
              curVal = value;
              this._loadRemoteSearchTree(
                searchtreeId,
                locale,
                displayLocale,
                optionref,
                initial,
                ignoremissingtranslations,
              );
            }
          });
        }

        // listen for changes in locale
        if (flatdata['locale']) {
          const ctrl = flatdata['locale'][0];

          // this._loadRemoteSearchTree(searchtreeId, locale, displayLocale, optionref, initial, ignoremissingtranslations);
          if (localeCtrlSubscription) {
            localeCtrlSubscription.unsubscribe();
          }

          localeCtrlSubscription = ctrl.valueChanges.subscribe((value: any) => {
            if (value && value != locale) {
              locale = value;
              displayLocale = this.globals.getDisplayLocale(); // todo: should be done better
              this._loadRemoteSearchTree(
                searchtreeId,
                locale,
                displayLocale,
                optionref,
                initial,
                ignoremissingtranslations,
              );
            }
          });
        }
      });
    }
  }

  private _loadRemoteSearchTree(
    searchtreeId: any,
    locale: any,
    displayLocale: any,
    optionref: any,
    initial: any,
    ignoremissingtranslations: any,
  ) {
    const key = this.getSearchTreeKey(
      searchtreeId,
      locale,
      displayLocale,
      optionref,
      initial,
      ignoremissingtranslations,
    );

    if (this._loadingTrees.hasOwnProperty(key)) {
      return;
    }

    if (!this._searchtrees.hasOwnProperty(key)) {

      this._loadingTrees[key] = true;

      let discriminator = this.dataService.getItem(optionref);
      if (discriminator == null && initial) {
        discriminator = initial;
      }

      this.getRemoteSearchtree(
        searchtreeId,
        locale,
        displayLocale,
        discriminator,
        ignoremissingtranslations,
      ).subscribe(
        (result) => {
          (this._searchtrees as any)[key] = result;
          this.dataService.refreshData(true, false);
          this._searchtrees.next(this._searchtrees);
          delete this._loadingTrees[key];
        },
        (error) => {
          this.logger.error(error);
          delete this._loadingTrees[key];
        },
      );
    }
  }

  // TODO: read ALL mappings? for offline usage?
  public getMapping(id: string, api: string): Observable<any> {
    const url = `${this.wisapiUrl}/${api}/${id}`;

    // first check the offline storage for this rest response
    const found = this.lstorage.keys().indexOf(url) > -1;
    if (this.globals.OFFLINE && found) {
      const res = this.lstorage.get(url);
      return of(res);
    }

    return this.http.get(url).pipe(
      map((res: any) => {
        if (this.globals.OFFLINE) {
          this.lstorage.set(url, res);
        }
        return res;
      }),
    );
  }

  private handleError(error: Response | any) {
    // we could use a remote logging infrastructure
    let errMsg: string;

    if (error.status === '500') {
      return of(0);
    }

    if (error instanceof Response) {
      const body = error.json() || '';
      const err = (body as any).error || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return throwError(errMsg);
  }
}
