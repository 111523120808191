import { Directive, ElementRef, EventEmitter, Output, HostListener } from '@angular/core';

@Directive({
  selector: '[appClickOutside]'
})
export class ClickOutsideDirective {
  @Output() clickedOutside = new EventEmitter<void>();

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  @HostListener('document:touchstart', ['$event'])
  handleGlobalClick(event: MouseEvent | TouchEvent): void {
    // Use composedPath() to get the event path, including shadow DOM elements
    const eventPath = event.composedPath();
    
    // Check if the path includes the current element
    const isInside = eventPath.includes(this.elementRef.nativeElement);

    if (!isInside) {
      this.clickedOutside.emit();
    }
  }
}
