import { RenderableNumericBase } from './renderable-numeric-base';
import { VocabOption } from './vocaboption';

export class RenderableRange extends RenderableNumericBase<number> {
  public options: VocabOption[] = [];
  public start: string; // can be an expression, so will be evaluated in component
  public end: string; // can be an expression, so will be evaluated in component
  public stepAsNumber: number;
  public override layout = 'radio'; // hint for display (radio, dropdown, accordion
  public left_label: string;
  public right_label: string;

  public multiple: boolean;
  // public dataService: DataService;
  // public vocabularyService: VocabularyService;

  constructor(options: {} = {}) {
    super(options);

    this.start = options['start'] || 1;
    this.end = options['end'] || 1;
    this.stepAsNumber = Number(+options['step'] || 1);
    this.layout = options['layout'] || 'dropdown';

    if (options['options']) {
      for (const opt of options['options']) {
        this.options.push(new VocabOption(opt));
      }
    }

    // NOTE: start + end are expressions. The component contains
    // the business logic to add the options
  }

  public override convertValue(value) {
    if (!this.multiple) {
      return (value === null || value == '') ? value : +value;
    } else {
      // todo: can we even have multiselect range values?
      return value;
    }
  }


}
