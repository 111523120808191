import { Injectable } from '@angular/core';
import { Logger } from './logger.service';
import { DataService } from './data.service';
import { ModelService } from './model.service';
import { SurveyService } from './survey.service';
import { GlobalService } from './global.service';
import { Survey } from '../Models/survey';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private survey: Survey;

  constructor(private globals: GlobalService, private surveyService: SurveyService) {
    this.surveyService.survey.subscribe((value) => {
      this.survey = value;
    });
  }

  public translate(label: string, id: string, bind?: string): string {
    let translated = label;

    if (this.survey) {
      let result = { label: label };

      if (this.globals.getGlobalTranslations() && id in this.globals.getGlobalTranslations()) {
        result['label'] = this.globals.getGlobalTranslations()[id].label;
      }

      let extraLabelIdKey = null;
      let extraLabelBindKey = null;
      let extraIdKey = null;
      let extraBindKey = null;

      if (this.globals.translationSuffix) {
        extraLabelIdKey = `${id}_label${this.globals.translationSuffix}`;
        extraIdKey = `${id}${this.globals.translationSuffix}`;
        if (bind) {
          extraLabelBindKey = `${bind}_label${this.globals.translationSuffix}`;
          extraBindKey = `${bind}${this.globals.translationSuffix}`;
        }
      }

      if (extraLabelIdKey && this.survey['translations'].hasOwnProperty(extraLabelIdKey)) {
        result = this.survey['translations'][extraLabelIdKey];
      } else if (extraLabelBindKey && this.survey['translations'].hasOwnProperty(extraLabelBindKey)) {
        result = this.survey['translations'][extraLabelBindKey];
      } else if (extraIdKey && this.survey['translations'].hasOwnProperty(extraIdKey)) {
        result = this.survey['translations'][extraIdKey];
      } else if (extraBindKey && this.survey['translations'].hasOwnProperty(extraBindKey)) {
        result = this.survey['translations'][extraBindKey];
      } else if (this.survey['translations'].hasOwnProperty(id + '_label')) {
        result = this.survey['translations'][id + '_label'];
      } else if (bind && this.survey['translations'].hasOwnProperty(bind + '_label')) {
        result = this.survey['translations'][bind + '_label'];
      } else if (this.survey['translations'].hasOwnProperty(id)) {
        result = this.survey['translations'][id];
      } else if (bind && this.survey['translations'].hasOwnProperty(bind)) {
        result = this.survey['translations'][bind];
      } else if (this.survey['translations'].hasOwnProperty(label)) {
        result = this.survey['translations'][label];
      }

      translated = result['label'];
    }
    return translated;
  }
}
