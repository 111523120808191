import { RenderableBase } from './renderable-base';

export class RenderableInput extends RenderableBase<string> {

  public rows: number;
  public min: string;
  public max: string;

  constructor(options: {} = {}) {
    super(options);
    this.rows = Number(+options['rows'] || 1);
    this.min = options['min'] || null;
    this.max = options['max'] || null;
  }
}
